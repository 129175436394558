import backendApis from 'utils/backendApis'
import {
  CPM_VIEWER_TYPES,
  CPM_ENTERING_COMPONENTS,
  CPM_PURCHASE_FREQUENCY_TYPES,
  CPM_GOODS_MODAL_TYPES,
  CPM_READY_MODAL_TYPES,
  CPM_VIDEO_MODAL_TYPES,
} from './cpmAdTypes'

// 구매 빈도에 따른 configs
const PURCHASE_FREQUENCY_AD_CONFIGS = {
  [CPM_PURCHASE_FREQUENCY_TYPES.LOW]: {
    viewerType: CPM_VIEWER_TYPES.VIDEO, // goods, video, none
    maxViewCount: 2, // 최대 광고 볼 수 있는 횟수
    intervalHours: 6, // 광고 보기 간격
    textMaxRewardAmount: 25, // 보상 최대 크기 (버튼 텍스트)
    browsingSeconds: 30,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.MEDIUM]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 2,
    intervalHours: 6,
    textMaxRewardAmount: 50,
    browsingSeconds: 30,
  },
  [CPM_PURCHASE_FREQUENCY_TYPES.HEAVY]: {
    viewerType: CPM_VIEWER_TYPES.GOODS,
    maxViewCount: 3,
    intervalHours: 4,
    textMaxRewardAmount: 50,
    browsingSeconds: 30,
  },
}

// viewerType에 따른 configs
const VIEWER_TYPE_AD_CONFIGS = {
  [CPM_VIEWER_TYPES.GOODS]: {
    buttonTitleText: '올팜 퀴즈 풀고 물받기',
    navigationModal: CPM_GOODS_MODAL_TYPES.QUIZ_AD,
    readyModal: CPM_READY_MODAL_TYPES.QUIZ_AD,
  },
  [CPM_VIEWER_TYPES.VIDEO]: {
    buttonTitleText: '올팜 퀴즈 풀고 물받기',
    navigationModal: CPM_VIDEO_MODAL_TYPES.QUIZ_AD,
    readyModal: CPM_READY_MODAL_TYPES.QUIZ_AD,
  },
  [CPM_VIEWER_TYPES.NONE]: {
    buttonTitleText: '',
    navigationModal: '',
    readyModal: '',
  },
}

// 정답 여부에 따른 configs
const CORRECT_TYPE_AD_CONFIGS = {
  true: {
    modalImageUrl: '/quiz/correctModal.png',
    modalTitleText: '정답을 맞췄어요!',
    textMaxRewardAmount: {
      [CPM_PURCHASE_FREQUENCY_TYPES.LOW]: 10,
      [CPM_PURCHASE_FREQUENCY_TYPES.MEDIUM]: 50,
      [CPM_PURCHASE_FREQUENCY_TYPES.HEAVY]: 50,
    },
  },
  false: {
    modalImageUrl: '/quiz/wrongModal.png',
    modalTitleText: '아쉽지만 정답이 아니에요',
    textMaxRewardAmount: {
      [CPM_PURCHASE_FREQUENCY_TYPES.LOW]: 10,
      [CPM_PURCHASE_FREQUENCY_TYPES.MEDIUM]: 10,
      [CPM_PURCHASE_FREQUENCY_TYPES.HEAVY]: 10,
    },
  },
}

// 버튼 PROPS를 동적으로 반환
const getButtonProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const viewerType = purchaseConfig.viewerType

  const viewerConfig = VIEWER_TYPE_AD_CONFIGS[viewerType]

  const BUTTON_PROPS = {
    codePushVersionLimit: '6.8.37', // 버전 제한
    buttonMainIconImageUrl:
      'https://assets.ilevit.com/ee3fb732-0732-4430-b4e6-ffa2f7640440.png',
    buttonSubtitleIconType: 'water', // 버튼 내 리워드 아이콘 - water, fertilizer, null
    viewerType,
    maxViewCount: purchaseConfig.maxViewCount,
    intervalHours: purchaseConfig.intervalHours,
    textMaxRewardAmount: purchaseConfig.textMaxRewardAmount,
    buttonTitleText: viewerConfig.buttonTitleText,
    navigationModal: viewerConfig.navigationModal,
    readyModal: viewerConfig.readyModal,
    buttonSubtitleText: `최대 ${purchaseConfig.textMaxRewardAmount}g, 하루 ${purchaseConfig.maxViewCount}번 가능`,
  }

  return BUTTON_PROPS
}

const getReadyModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  const viewerType = purchaseConfig.viewerType

  const READY_MODAL_PROPS = {
    userPurchaseFrequencyType,
    modalPath: VIEWER_TYPE_AD_CONFIGS[viewerType].navigationModal,
    modalBackgroundImageUrl: '/quiz/modal.png',
    inCorrectRewardAmount: 10,
    answerButtonTextBeforeSelection: '정답을 골라주세요',
    answerButtonTextAfterSelection: '정답 제출하기',
  }

  return READY_MODAL_PROPS
}

// 상품 모달 PROPS를 동적으로 반환
const getGoodsModalProps = async (userPurchaseFrequencyType) => {
  const quizAdStatus = await backendApis.getCPMAdStatusByType({
    type: CPM_ENTERING_COMPONENTS.QUIZ_AD,
  })
  await new Promise((resolve) => setTimeout(resolve, 200))
  // const { rewardAmount, rewardStatus: isCorrect } = quizAdStatus?.data
  const rewardAmount = quizAdStatus?.data?.rewardAmount || 10
  const isCorrect = quizAdStatus?.data?.rewardStatus || true

  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]
  const correctConfig = CORRECT_TYPE_AD_CONFIGS[isCorrect]

  const GOODS_MODAL_COMMON_PROPS = {
    BROWSING_TIME_SEC: purchaseConfig.browsingSeconds,
    FIRST_BUTTON_TEXT: '준비 중',
    FINAL_BUTTON_TEXT: `구경하고 물 ${rewardAmount}g 받기`,
    MODAL_IMAGE_URL: correctConfig.modalImageUrl,
    CLOSE_BUTTON_POSITION: { top: '18%', right: '15%' },
    ENTERING_COMPONENT: CPM_ENTERING_COMPONENTS.QUIZ_AD,
    ANIMATION_DURATION: 1500,
    MODAL_TEXT_1: correctConfig.modalTitleText,
    MODAL_TEXT_2: `상품을 ${purchaseConfig.browsingSeconds}초 보면 물 ${rewardAmount}g을 받아요`,
  }

  const CPM_PURCHASE_SCREEN_PROPS = {
    title: '퀴즈 상품 구경하기',
    enteringComponent: CPM_ENTERING_COMPONENTS.QUIZ_AD,
    questTimer: purchaseConfig.browsingSeconds / 60,
    userPurchaseFrequencyType: userPurchaseFrequencyType,
    scrollQuestReward: `물 ${rewardAmount}g`,
    // NOTE - deprecated
    image: 'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png',
    ratio: 99 / 360,
    buyingReward: '고급 비료 5개, 물 1,000g',
    questRewardText: `초간 구경하면 물을 ${rewardAmount}g 받아요!`,
    isTimerOn: true,
    // NOTE - deprecated
    enabledIntervalTimer: true,
  }

  const GOODS_MODAL_PROPS = {
    GOODS_MODAL_COMMON_PROPS,
    CPM_PURCHASE_SCREEN_PROPS,
  }

  return GOODS_MODAL_PROPS
}

const getVideoModalProps = (userPurchaseFrequencyType) => {
  const purchaseConfig =
    PURCHASE_FREQUENCY_AD_CONFIGS[userPurchaseFrequencyType]

  return {
    VIDEO_MODAL_COMMON_PROPS: {
      ...purchaseConfig,
      viewerType: CPM_VIEWER_TYPES.VIDEO,
    },
    CORRECT_TYPE_AD_CONFIGS,
    CPM_PURCHASE_SCREEN_PROPS: {
      ...VIEWER_TYPE_AD_CONFIGS[CPM_VIEWER_TYPES.VIDEO],
      enteringComponent: CPM_ENTERING_COMPONENTS.QUIZ_AD,
      userPurchaseFrequencyType,
    },
  }
}

export {
  getButtonProps,
  getGoodsModalProps,
  getReadyModalProps,
  getVideoModalProps,
}
