import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'

const ChangePlantForPineapple = observer(
  ({ token, farmData, setFarmData, setOpenChangePineappleModal }) => {
    const updateModalOpened = async () => {
      if (
        farmData?.itemType === 'pineapple' &&
        !farmData?.didShowPlantFromPineappleToOtherModal
      ) {
        setFarmData({
          ...farmData,
          didShowPlantFromPineappleToOtherModal: new Date(),
        })
        await backendApis.updatelastUpdatedAt(token, 'PUT', {
          updateType: 'didShowPlantFromPineappleToOtherModal',
        })
      }
    }

    useEffect(() => {
      updateModalOpened()
    }, [])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1000,
            width: '100%',
            height: '100vh',
            background: 'rgba(0,0,0,0.8)',
          }}
        />
        <div
          style={{
            height: '130vw',
            position: 'absolute',
            zIndex: 1001,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            width: '80vw',
            background:
              'linear-gradient(360deg, rgba(255,185,80,1) 0%, rgba(255,244,116,1) 100%)',
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 4px 0px 4px',
          }}
        >
          <div
            style={{
              fontFamily: 'maplestory',
              color: '#402C24',
              fontSize: '6vw',
              textAlign: 'center',
              marginTop: '4.4vw',
              marginBottom: '1vw',
              fontWeight: 'bold',
            }}
          >
            작물 변경 안내
          </div>
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '4vw',
              width: '92%',
              height: '50vh',
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '0vw',
              paddingBottom: '16vw',
              marginTop: '4vw',
              marginBottom: '6vw',
              alignItems: 'center',
              overflowY: 'scroll',
            }}
          >
            <div
              style={{
                fontFamily: 'maplestory',
                color: '#402C24',
                textAlign: 'start',
                width: '86%',
                fontSize: '4vw',
              }}
            >
              <br></br> 안녕하세요
              <br></br>
              <br></br> 고객님께서 현재 키우고 계신 작물이
              <br></br>
              농가 사정으로 공급에 문제가 생겨
              <br></br>
              수확이 불가능한 상황이 되었어요
              <br></br>
              <br></br>
              다른 작물로 변경할 수 있게
              <br></br>
              작물 변경권을 지급해드려요
              <br></br>
              <br></br>한 번 작물을 변경하면
              <br></br>다른 작물로 변경할 수 없으니
              <br></br>참고 부탁드려요
              <br></br>{' '}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '2vw',
                }}
              >
                <img
                  src='/icon/changePlantIcon.png'
                  alt=''
                  style={{ width: '35vw' }}
                />
              </div>
            </div>
          </div>
          <button
            style={{
              background:
                'linear-gradient(180deg, rgba(255,212,61,1) 0%, rgba(255,226,125,1) 100%)',
              margin: '0px',
              padding: '4vw 12vw 4vw 12vw',
              borderRadius: '12vw',
              marginBottom: '16px',
              width: '100%',
              fontSize: '4vw',
              fontFamily: 'maplestory',
              color: 'black',
              marginTop: '4vw',
            }}
            onClick={() => {
              setOpenChangePineappleModal(false)
              ModalStore.setIsAutoAppearModal(true)
              ModalStore.setIsModalOpen('changePlant')
            }}
          >
            {'작물 변경하러 가기'}
          </button>
        </div>
      </>
    )
  },
)

export default ChangePlantForPineapple
