import React, { useState, useEffect, useRef } from 'react'
import { useSearchParam } from 'react-use'
import Layout from '../comps/atoms/layout'
import backendApis from '../utils/backendApis'
import LoadingIndicator from '../comps/loadingIndicator'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { API_URI } from 'utils/constant'
import ToastStore from 'store/ToastStore'
import moment from 'moment'
import PokeFriendHeartIndicatorModal from 'comps/atoms/pokeFriendHeartIndicatorModal'
import commaNumber from 'comma-number'
import ImageText from 'utils/imageText'
import ModalStore from 'store/ModalStore'
import ResurrectionModal from 'comps/atoms/Modals/ResurrectionModal'
import GganbuFarmComponent from 'comps/molecules/gganbuList/GganbuFarmComponent'
import DummyGganbuFarmComponent from 'comps/molecules/gganbuList/DummyGganbuFarmComponent'
import GganbuInviteModal from 'comps/atoms/gganbuInviteModal'
import GganbuDeleteModal from 'comps/atoms/gganbuDeleteModal'
import ResurrectNudgeStampModal from 'comps/atoms/resurrectNudgeStampModal'
import ABStore from 'store/ABStore'
import AB from 'utils/ab_v3'
import UserStore from 'store/UserStore'

const fetcher = async (url, token, method = 'GET', params = {}) => {
  const resource = method === 'GET' ? url + new URLSearchParams(params) : url
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} }
  init['method'] = method
  init.headers['Content-Type'] = 'application/json'
  init.headers['x-access-token'] = token
  try {
    const res = await fetch(API_URI + resource, init)

    const data = await res.json()

    return data
  } catch (err) {
    return null
  }
}

const parseRewardContent = (rewardType, amount) => {
  let prefix = amount
  let unit = ''
  let suffix = ' 획득!'
  if (rewardType === 'water') {
    unit = 'g'
  } else if (rewardType === 'nutriment') {
    unit = ''
  } else if (rewardType === 'fertilizer' || rewardType === 'fertilizerSet') {
    unit = '개'
  } else if (rewardType === 'specialPurchase') {
    unit = '개'
  } else if (rewardType === 'jangbogiCoin') {
    unit = '개'
  } else if (rewardType === 'waterAndFertilizer') {
    prefix = `${amount}g + 일반비료 1`
    unit = '개'
    suffix = ''
  } else if (rewardType === 'waterAndFertilizerSet') {
    prefix = `${amount}g + 고급비료 1`
    unit = '개'
    suffix = ''
  }

  return `${prefix}${unit}${suffix}`
}

let isPressed = false

const GganbuFarmList = observer(() => {
  const scrollContainerRef = useRef(null)
  const rewardTimeoutRef = useRef(null)

  const token = useSearchParam('token')
  const itemType = useSearchParam('itemType')
  const [isLoading, setIsLoading] = useState(false)

  const [myFarmData, setMyFarmData] = useState({})
  const [gganbuData, setGganbuData] = useState(
    JSON.parse(window.localStorage.getItem('gganbuData')) || null,
  )
  const [showQuickFeedback, setShowQuickFeedback] = useState(false)
  const [waterGivingGganbuId, setWaterGivingGganbuId] = useState('')
  const [rewardModalInfo, setRewardModalInfo] = useState({
    modalType: '',
    show: false,
    gganbuData: '',
    rewardType: '',
    amount: 0,
    message: '',
    style: {},
  })
  const [pokeModalInfo, setPokeModalInfo] = useState({
    show: false,
    gganbuId: '',
  })
  const [isGivingWater, setIsGivingWater] = useState(false)

  useEffect(() => {
    const fetchGganbuList = async () => {
      if (!gganbuData?.gganbuList?.length) {
        const result = await backendApis.getGganbuList(token)

        if (result?.data) {
          setGganbuData(result?.data)
          window.localStorage.setItem(
            'gganbuData',
            JSON.stringify(result?.data),
          )
        }
      }
    }

    const fetchMyAlfarmData = async () => {
      if (!myFarmData?.userId) {
        fetcher(`/games/start-alfarm`, token, 'POST', {
          itemType: itemType,
        }).then((data) => {
          if (data?.userId) {
            setMyFarmData(data)
          } else {
            alert('정보를 불러오지 못했어요. 잠시 후 다시 시도해 주세요.')
          }
        })
      }

      const savedPosition = window.localStorage.getItem(
        `gganbuFarmListScrollPosition_${moment().format('YYYYMMDD')}`,
      )
      if (savedPosition && scrollContainerRef.current) {
        setTimeout(() => {
          scrollContainerRef.current.scrollTop = parseInt(savedPosition, 10)
        }, 100)
      }

      const feedbackGiven = window.localStorage.getItem(
        'gganbuFarmList_quickFeedback',
      )

      if (!feedbackGiven) {
        setShowQuickFeedback(true)
      }
    }

    setIsLoading(true)
    if (token) {
      fetchGganbuList()
      fetchMyAlfarmData()
      setIsLoading(false)
    } else {
      alert('정보를 불러오지 못했어요. 잠시 후 다시 시도해 주세요.')
      setIsLoading(false)
    }
  }, [token, gganbuData?.gganbuList?.length, myFarmData?.userId, itemType])

  useEffect(() => {
    const handleScroll = (e) => {
      // 스크롤 위치 기억하는 용도
      window.localStorage.setItem(
        `gganbuFarmListScrollPosition_${moment().format('YYYYMMDD')}`,
        e.target.scrollTop,
      )
    }

    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const waterGganbuFarm = async ({ gganbuId, count }) => {
    if (isGivingWater) return
    if (!isPressed || !pokeModalInfo?.show) {
      isPressed = true
      setIsGivingWater(true)

      let result
      if (count === 1) {
        result = await backendApis.giveWaterBasketToGganbu(token, 'POST', {
          gganbuId: gganbuId,
        })
      } else if (count === 5) {
        result = await backendApis.giveWaterBasketFiveToGganbu(token, 'POST', {
          gganbuId: gganbuId,
        })
      }

      if (!result?.data?.success) {
        ToastStore.toastOn({
          type: 'error',
          message: '일 최대 20번까지 물을 줄 수 있어요\n내일 다시 시도해주세요',
          duration: 3000,
        })
        setIsGivingWater(false)
        isPressed = false
        return false
      }
      let tmpFarmData = myFarmData
      tmpFarmData.water -= result?.data?.point
      setMyFarmData(tmpFarmData)

      // 물 주는 애니메이션
      setWaterGivingGganbuId(gganbuId)
      setTimeout(() => {
        setWaterGivingGganbuId('')
        setIsGivingWater(false)
      }, 1500)

      isPressed = false
      return true
    }
  }

  const getGoodsFromGganbu = async ({
    gganbuId,
    gganbuData,
    goodsType,
    gganbuFarmResetInfo,
  }) => {
    if (!isPressed || !pokeModalInfo?.show || !rewardModalInfo?.show) {
      isPressed = true

      if (goodsType === 'gganbuBox') {
        const bonusRewardBox = async () => {
          if (!gganbuFarmResetInfo?.didGiveWaterToGganbuToday) {
            if (rewardTimeoutRef.current) {
              clearTimeout(rewardTimeoutRef.current)
            }

            setRewardModalInfo({
              modalType: 'reward',
              show: true,
              rewardType: 'waterAndFertilizerSet',
              style: {
                padding: '2vw',
                fontSize: '4.2vw',
              },
              message: `친구에게 물을 주면
              맞팜상자를 열 수 있어요!`,
            })
            rewardTimeoutRef.current = setTimeout(() => {
              setRewardModalInfo({
                modalType: 'reward',
                show: false,
                rewardType: '',
                amount: 0,
                message: '',
              })
            }, 3000)

            isPressed = false
            return false
          } else if (gganbuData?.isLeftUser) {
            ModalStore.setIsModalOpen('resurrection')
            setRewardModalInfo({
              modalType: 'resurrection',
              gganbuData: gganbuData,
            })
            isPressed = false
            return false
          }

          const result = await backendApis.bonusRewardBox(token, 'POST', {
            gganbuId,
          })

          if (result?.data?.success === false) {
            if (result?.data?.msg === 'exceed limit') {
              ToastStore?.toastOn({
                type: 'error',
                message: '[일일 제한 초과] 내일 다시 시도해주세요',
                duration: 3000,
              })
            }
            isPressed = false
            return false
          }

          if (result?.data?.success === true) {
            if (rewardTimeoutRef.current) {
              clearTimeout(rewardTimeoutRef.current)
            }
            const bonusReward = result?.data?.reward
            const rewardType = Object.keys(bonusReward)?.[0]
            const rewardAmount = Object.values(bonusReward)?.[0]
            const rewardContent = parseRewardContent(rewardType, rewardAmount)
            setRewardModalInfo({
              modalType: 'reward',
              show: true,
              rewardType,
              amount: rewardAmount,
              message: rewardContent,
            })
            rewardTimeoutRef.current = setTimeout(() => {
              setRewardModalInfo({
                modalType: 'reward',
                show: false,
                rewardType: '',
                amount: 0,
                message: '',
              })
            }, 3000)

            isPressed = false
            return true
          }
        }

        return await bonusRewardBox()
      } else if (goodsType === 'water') {
        const waterFromGganbu = async () => {
          if (
            // 물 받은지 4시간이 안 지난 경우
            moment().isBefore(
              moment(gganbuData?.lastGetWaterAt).add(4, 'hours'),
            )
          ) {
            ToastStore?.toastOn({
              type: 'emoji',
              emoji: '🕰️',
              message: `${
                moment(gganbuData?.lastGetWaterAt)
                  .add(4, 'hours')
                  .diff(moment(), 'minutes') > 60
                  ? `${moment(gganbuData?.lastGetWaterAt)
                      .add(4, 'hours')
                      .format('H시 m분')}`
                  : `  ${moment(gganbuData?.lastGetWaterAt)
                      .add(4, 'hours')
                      .diff(moment(), 'minutes')}분 후`
              }에 다시 받을 수 있어요`,
              duration: 3000,
            })

            isPressed = false
            return false
          } else if (gganbuData?.isLeftUser) {
            const stampInfo = myFarmData?.gganbuList?.find(
              (each) => each.userId === gganbuId && !each.deletedAt,
            )?.stampInfo

            const isStampTester = ABStore.gganbuStampTester

            if (
              isStampTester &&
              stampInfo &&
              !stampInfo.closeRewardGivenAt &&
              moment().isBefore(moment('2024-11-19 00:00:00'))
            ) {
              if (
                !myFarmData.gganbuList.find(
                  (each) => each.userId === gganbuId && !each.deletedAt,
                )
              ) {
                return
              }
              setRewardModalInfo({
                modalType: 'resurrectStamp',
                show: true,
                gganbuData: myFarmData.gganbuList.find(
                  (each) => each.userId === gganbuId && !each.deletedAt,
                ),

                gganbuFarmResetInfo: gganbuFarmResetInfo,
              })
              ModalStore.setIsModalOpen('resurrectStamp')
              // 스탬프 화면으로 이동하는 물받기 클릭 로그
              backendApis.logABclick(token, 'PUT', {
                location: 'gganbuStampModal',
                action: 'openModalFromNewUI',
                data: {
                  description: 'open stamp modal from new UI',
                },
              })
            } else {
              if (rewardTimeoutRef.current) {
                clearTimeout(rewardTimeoutRef.current)
              }

              setRewardModalInfo({
                modalType: 'reward',
                show: true,
                style: {
                  padding: '2vw',
                  fontSize: '4.2vw',
                },
                message: `${
                  gganbuData.userName?.length > 6
                    ? `${gganbuData.userName.slice(0, 6)}..`
                    : `${gganbuData.userName.slice(0, 6)}`
                }님이 작물을 돌보지 않아
                      물을 받을 수 없어요. 😔
                      친구가 다시 돌아오면
                      물을 받을 수 있어요`,
              })
              rewardTimeoutRef.current = setTimeout(() => {
                setRewardModalInfo({
                  modalType: 'reward',
                  show: false,
                  style: {},
                  message: '',
                })
              }, 3000)
            }
            isPressed = false
            return false
          }

          const result = await backendApis.waterFromGganbu(token, 'POST', {
            gganbuId: gganbuId,
          })

          if (result?.data?.success === false) {
            if (result?.data?.msg === 'exceed limit') {
              ToastStore?.toastOn({
                type: 'error',
                message: '[일일 제한 초과] 내일 다시 시도해주세요',
                duration: 3000,
              })
            }
            isPressed = false
            return false
          }

          let tmpFarmData = myFarmData
          tmpFarmData.water += result?.data?.water
          setMyFarmData(tmpFarmData)

          if (rewardTimeoutRef.current) {
            clearTimeout(rewardTimeoutRef.current)
          }
          setRewardModalInfo({
            modalType: 'reward',
            show: true,
            rewardType: 'water',
            amount: result?.data?.water,
            message: `물 ${result?.data?.water}g 획득!`,
          })
          rewardTimeoutRef.current = setTimeout(() => {
            setRewardModalInfo({
              modalType: 'reward',
              show: false,
              rewardType: '',
              amount: 0,
              message: '',
            })
          }, 3000)

          isPressed = false
          return true
        }

        return await waterFromGganbu()
      }
    }
  }

  const pokeFriend = async () => {
    const selectedGganbuId = pokeModalInfo[pokeModalInfo.gganbuId]
    if (!pokeModalInfo?.show) {
      return
    }
    if (!selectedGganbuId?.userIdTurn) {
      if (selectedGganbuId?.pokeCount >= 4) {
        ToastStore.toastOn({
          type: 'emoji',
          message: `오늘 콕! 찌르기를 완료했어요`,
          emoji: '❤️',
          duration: 1500,
        })
      } else {
        ToastStore.toastOn({
          type: 'emoji',
          message: `친구가 찌를 차례에요`,
          emoji: '👈',
          duration: 1500,
        })
      }
    }

    if (selectedGganbuId?.pokeCount >= 4) return

    const result = await backendApis.pokeFriend(token, 'PUT', {
      gganbuId: pokeModalInfo.gganbuId,
      gganbuName: selectedGganbuId?.gganbuData?.userName,
    })
    // 200: 찌르기 6번째 마무리 - 성공
    // 201: 이미 6번 다 찌름
    // 202: 찌르기 진행중
    // 203: 내 차례 아님
    // 참고: finishedAt이 있는 상태면 백엔드에서 pokeCount가 5로 설정되어 넘어옴

    if (result?.data?.status === 200) {
      ToastStore.toastOn({
        type: 'emoji',
        message: `콕 찔렀어요!`,
        emoji: '👉',
        duration: 1500,
      })
      setPokeModalInfo((prevState) => ({
        ...prevState, // 기존의 pokeModalInfo를 유지
        [pokeModalInfo.gganbuId]: {
          ...prevState[pokeModalInfo.gganbuId],
          pokeCount: +1,
          userIdTurn: false,
        },
      }))
    } else if (result?.data?.status === 201) {
      if (rewardTimeoutRef.current) {
        clearTimeout(rewardTimeoutRef.current)
      }
      setRewardModalInfo({
        modalType: 'reward',
        show: true,
        rewardType: 'water',
        amount: result?.data?.water,
        message: `물 ${result?.data?.water}g 획득!`,
      })
      rewardTimeoutRef.current = setTimeout(() => {
        setRewardModalInfo({
          modalType: 'reward',
          show: false,
          rewardType: '',
          amount: 0,
          message: '',
        })
      }, 3000)

      let tmpFarmData = myFarmData
      tmpFarmData.water += result?.data?.water
      setMyFarmData(tmpFarmData)
    } else if (result?.data?.status === 202) {
      ToastStore.toastOn({
        type: 'emoji',
        message: `콕 찔렀어요!`,
        emoji: '👉',
        duration: 1500,
      })

      setPokeModalInfo((prevState) => ({
        ...prevState, // 기존의 pokeModalInfo를 유지
        [pokeModalInfo.gganbuId]: {
          ...prevState[pokeModalInfo.gganbuId],
          pokeCount: +1,
          userIdTurn: false,
        },
      }))
    } else if (result?.data?.status === 203) {
      ToastStore.toastOn({
        type: 'emoji',
        message: `친구가 찌를 차례에요`,
        emoji: '👈',
        duration: 1500,
      })
    }
  }

  const addStamp = async ({ gganbuFarmResetInfo }) => {
    if (isPressed || !rewardModalInfo?.show) {
      return
    }

    isPressed = true

    const selectedGganbuData = rewardModalInfo?.gganbuData
    const stampInfo = selectedGganbuData?.stampInfo
    const alreadyStampedToday =
      stampInfo &&
      moment(stampInfo?.lastStampedAt).format('YYYY-MM-DD') ===
        moment().format('YYYY-MM-DD')

    // 이미 도장 찍었거나, 오늘 물을 아직 안 준 경우

    if (alreadyStampedToday) {
      ToastStore.toastOn({
        type: 'emoji',
        message: '오늘 이미 도장을 찍었어요',
        emoji: '🌟',
        duration: 1500,
      })
      isPressed = false
      return false
    }

    if (!gganbuFarmResetInfo?.didGiveWaterToGganbuToday) {
      const result = waterGganbuFarm({
        gganbuId: selectedGganbuData?.userId,
        count: 1,
      })
      if (result === false) {
        isPressed = false
        return false
      }
    }

    if (!stampInfo || stampInfo?.count >= 0) {
      const result = await backendApis.addGganbuStamp(token, 'PUT', {
        gganbuId: selectedGganbuData?.userId,
      })

      if (result?.status === 200) {
        let tmpFarmData = myFarmData

        if (result.data.reward) {
          // 물 받기
          tmpFarmData.water += result?.data?.reward
          tmpFarmData.gganbuList = tmpFarmData.gganbuList.map((each) => {
            if (each.userId === selectedGganbuData?.userId && !each.deletedAt) {
              return {
                ...each,
                stampInfo: {
                  count: 0,
                  lastStampedAt: new Date(),
                },
              }
            }
            return each
          })

          if (rewardTimeoutRef.current) {
            clearTimeout(rewardTimeoutRef.current)
          }
          setRewardModalInfo({
            modalType: 'reward',
            show: true,
            rewardType: 'water',
            amount: result?.data?.reward,
            message: `물 ${result?.data?.reward}g 획득!`,
          })
          rewardTimeoutRef.current = setTimeout(() => {
            setRewardModalInfo({
              modalType: 'reward',
              show: false,
              rewardType: '',
              amount: 0,
              message: '',
            })
            ModalStore.setIsModalOpen('basic')
          }, 3000)
        } else {
          // tmpFarmData.gganbuList에서 해당 gganbuId를 찾아서 stampInfo.count를 업데이트
          tmpFarmData.gganbuList = tmpFarmData.gganbuList.map((each) => {
            if (each.userId === selectedGganbuData?.userId && !each.deletedAt) {
              return {
                ...each,
                stampInfo: {
                  count: (each?.stampInfo?.count || 0) + 1,
                  lastStampedAt: new Date(),
                },
              }
            }
            return each
          })
        }

        setMyFarmData(tmpFarmData)

        isPressed = false
        return true
      }
    }

    isPressed = false
    return false
  }

  const finishStamp = async () => {
    const selectedGganbuData = rewardModalInfo?.gganbuData

    const result = await backendApis.closeGganbuStamp(token, 'PUT', {
      gganbuId: selectedGganbuData?.userId,
    })

    if (result?.status === 200) {
      let tmpFarmData = myFarmData

      if (result.data.reward) {
        // 물 받기
        tmpFarmData.water += result?.data?.reward
        tmpFarmData.gganbuList = tmpFarmData.gganbuList.map((each) => {
          if (each.userId === selectedGganbuData?.userId && !each.deletedAt) {
            return {
              ...each,
              stampInfo: {
                closeRewardGivenAt: new Date(),
              },
            }
          }
          return each
        })

        if (rewardTimeoutRef.current) {
          clearTimeout(rewardTimeoutRef.current)
        }
        setRewardModalInfo({
          modalType: 'reward',
          show: true,
          rewardType: 'water',
          amount: result?.data?.reward,
          message: `물 ${result?.data?.reward}g 획득!`,
        })
        rewardTimeoutRef.current = setTimeout(() => {
          setRewardModalInfo({
            modalType: 'reward',
            show: false,
            rewardType: '',
            amount: 0,
            message: '',
          })
          ModalStore.setIsModalOpen('basic')
        }, 3000)
      }
      setMyFarmData(tmpFarmData)

      return true
    }

    return false
  }

  const AddGganbu = () => {
    const gganbuLength = gganbuData?.gganbuList?.length

    if (gganbuLength && gganbuLength >= 20) return null
    return (
      <div>
        <button
          onClick={() => {
            ModalStore.setIsModalOpen('gganbuInvite')
          }}
        >
          <img
            className='w-[46vw] h-auto'
            alt=''
            src={'/icon/gganbuFarm/icn_gganbuFarmList_addGganbu.png'}
          />
        </button>
      </div>
    )
  }

  const DeleteGganbu = () => {
    const gganbuLength = gganbuData?.gganbuList?.length
    if (gganbuLength < 1) return null
    return (
      <div>
        <button
          onClick={() => {
            ModalStore.setIsModalOpen('gganbuDelete')
          }}
        >
          <img
            className='w-[46vw] h-auto'
            alt=''
            src={'/icon/gganbuFarm/icn_gganbuFarmList_deleteGganbu.png'}
          />
        </button>
      </div>
    )
  }

  const QuickFeedbackComponent = () => {
    const onClickFeedback = async (feedback) => {
      setShowQuickFeedback(false)
      window.localStorage.setItem('gganbuFarmList_quickFeedback', true)
      backendApis.logABclick(token, 'PUT', {
        location: 'gganbuFarm',
        action: 'quickFeedback',
        data: {
          description: 'gave quick feedback',
          feedback: feedback,
        },
      })

      ToastStore.toastOn({
        type: 'emoji',
        message: '소중한 의견 주셔서 감사해요',
        emoji: '🙏',
        duration: 3000,
      })
    }

    return (
      <div className='w-[80vw] text-center text-[4vw] my-[4vh]'>
        <div> 잠깐, 맞팜 목록 모아보기 기능은 어떠셨나요?</div>
        <div className='mt-[4vw] flex flex-row justify-between'>
          <button
            className='w-[36vw] py-[3vw] border-[0.5vw] border-white bg-[#FFFFFF40] rounded-lg font-bold'
            onClick={() => {
              onClickFeedback('good')
            }}
          >
            ✅ 편해요
          </button>
          <button
            className='w-[36vw] py-[3vw] border-[0.5vw] border-white bg-[#FFFFFF40] rounded-lg font-bold'
            onClick={() => {
              onClickFeedback('bad')
            }}
          >
            ⚠️ 불편해요
          </button>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <Layout isLoading={isLoading}>
        <LoadingIndicator isLoading={isLoading} />
      </Layout>
    )
  }

  return (
    <div className='z-10 w-full h-full fadeInGganbu'>
      <img
        className='absolute z-0 w-full h-full'
        src='/bg/gganbuBackground.png'
        alt=''
      />

      <header className='relative h-[15vh] flex justify-between pt-[10vw]'>
        <div className='flex flex-row justify-between items-center mt-[2vw]'>
          <NavLink to={`/farmPage?token=${token}&itemType=${itemType}`}>
            <button className='w-[8vw] ml-[3vw]' action='goBack'>
              <img src='/icon/Back.png' alt='' />
            </button>
          </NavLink>
        </div>
        <div className='text-black relative mr-[2vw]'>
          <img
            src='/icon/gganbuFarm/icn_gganbuFarmList_waterStatus.png'
            className='w-[40vw] h-auto'
            alt=''
          />
          <div className='absolute top-[3vw] left-[17vw] font-semibold'>
            <div className='text-[3.4vw] text-[#402C24] -mb-[1vw] '>
              나의 물
            </div>
            <div className='text-[4.6vw] text-[#402C24] top-[10vw]'>
              {commaNumber(Math.floor(myFarmData?.water || 0))}g
            </div>
          </div>
        </div>
      </header>

      <div
        className='relative text-black text-[3vw] h-[90vh] overflow-y-scroll pt-[2vh] pb-[6vh] flex flex-col items-center'
        ref={scrollContainerRef}
      >
        {gganbuData?.gganbuList?.map((each) => {
          return (
            <GganbuFarmComponent
              key={each.userId}
              token={token}
              gganbuData={each}
              waterGivingGganbuId={waterGivingGganbuId}
              myFarmData={myFarmData}
              waterGganbuFarm={waterGganbuFarm}
              getGoodsFromGganbu={getGoodsFromGganbu}
              pokeModalInfo={pokeModalInfo}
              setPokeModalInfo={setPokeModalInfo}
            />
          )
        })}
        {!gganbuData?.gganbuList ||
          (gganbuData?.gganbuList?.length < 3 && (
            <DummyGganbuFarmComponent
              rewardTimeoutRef={rewardTimeoutRef}
              setRewardModalInfo={setRewardModalInfo}
            />
          ))}

        {showQuickFeedback && <QuickFeedbackComponent />}

        <div className='flex justify-center w-[92vw] mt-[4vh]'>
          <AddGganbu />
          <DeleteGganbu />
        </div>
      </div>

      {/* 아래는 모달 목록 */}
      {rewardModalInfo?.modalType === 'reward' && rewardModalInfo.show && (
        <button
          className='absolute flex flex-col items-center bg-[#000000b1] z-20 w-[60vw] py-[8vw] top-[70vw] left-[20vw] rounded-xl text-center text-[6vw]'
          onClick={() => {
            setRewardModalInfo({
              ...rewardModalInfo,
              show: false,
            })
          }}
        >
          {rewardModalInfo.rewardType && (
            <img
              className={
                rewardModalInfo.rewardType === 'gganbuNudge'
                  ? 'w-[54vw] my-[4vw]'
                  : 'w-[20vw] h-full my-[4vw]'
              }
              src={`/icon/${rewardModalInfo.rewardType}.png`}
              alt=''
            />
          )}
          <ImageText
            text={rewardModalInfo.message}
            style={rewardModalInfo?.style}
          />
        </button>
      )}
      {pokeModalInfo?.show && ModalStore?.isModalOpen === 'pokeModal' && (
        <div className='w-[70vw]'>
          <PokeFriendHeartIndicatorModal
            userIdTurn={pokeModalInfo[pokeModalInfo.gganbuId]?.userIdTurn}
            pokeCount={pokeModalInfo[pokeModalInfo.gganbuId]?.pokeCount}
            pokeFriend={pokeFriend}
            pokeModalInfo={pokeModalInfo[pokeModalInfo.gganbuId]} // 해당 userId의 모달 정보만 전달
            setPokeModalInfo={setPokeModalInfo}
          />
        </div>
      )}
      {ModalStore?.isModalOpen === 'resurrectStamp' && (
        <div className='w-[70vw]'>
          <ResurrectNudgeStampModal
            gganbuData={rewardModalInfo?.gganbuData}
            addStamp={addStamp}
            finishStamp={finishStamp}
            gganbuFarmResetInfo={rewardModalInfo?.gganbuFarmResetInfo}
          />
        </div>
      )}

      {rewardModalInfo?.modalType === 'resurrection' &&
        ModalStore?.isModalOpen === 'resurrection' && (
          <ResurrectionModal
            gganbuName={rewardModalInfo?.gganbuData?.userName}
          />
        )}
      {ModalStore?.isModalOpen === 'gganbuInvite' && (
        <GganbuInviteModal token={token} userId={myFarmData?.userId} />
      )}
      {ModalStore?.isModalOpen === 'gganbuDelete' && (
        <GganbuDeleteModal
          gganbuData={gganbuData}
          token={token}
          setGganbuData={setGganbuData}
        />
      )}
    </div>
  )
})

export default GganbuFarmList
