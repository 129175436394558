import { observer } from 'mobx-react-lite'
import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ModalStore from 'store/ModalStore'
import UserStore from 'store/UserStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import backendApis from 'utils/backendApis'
import { BADAHAM_V2_COIN_IMAGE_URL } from './_constatns/badahamAssets'

import AB from 'utils/ab_v3'
import ABStore from 'store/ABStore'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import { appBridgeSender } from 'utils/appBridgeSender'
import { safeCodepushVersionCompare } from 'utils/semver'

const MissionList = observer(
  ({
    dailyBadahamData,
    refetchDailyBadahamData,
    refetchCoinCount,
    badahamConfig,
  }) => {
    const throttleRef = useRef({})
    const [loadingStates, setLoadingStates] = useState({})
    const navigate = useNavigate()

    const internalAdDescriptionRef = useRef(null)

    const codePushVersionAvailable = useCheckCodePushVersion()

    const userCodePushVersion = UserStore.codePushVersionFromAlwayzApp
    const [currentTime, setCurrentTime] = useState(new Date().getTime())

    const [trafficAd, setTrafficAd] = useState(null)

    const browsingCodepushAvailable =
      safeCodepushVersionCompare(userCodePushVersion, '7.1.19') >= 0

    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentTime(new Date().getTime())
      }, 1000)

      return () => clearInterval(timer)
    }, [])

    useEffect(() => {
      const getTrafficAds = async () => {
        const query = {
          product: 'ALFARM',
          placement: 'BADAHAM_MISSION',
          platform: UserStore.offerwallInfo.platform,
          ifa: UserStore.offerwallInfo.ifa,
          ifv: UserStore.offerwallInfo.ifv,
          enableExternalAds: false, // enabling buzzvil
          adCount: 1,
        }
        const result = await backendApis.getTrafficAds(query)
        if (result?.status === 200) {
          const trafficAd = result?.data?.data?.[0]

          setTrafficAd(trafficAd)

          if (trafficAd?.impressionUrls) {
            trafficAd?.impressionUrls?.forEach((url) => {
              fetch(url).catch((error) => {
                console.error('광고 노출 URL 호출 실패:', error)
              })
            })
          }
        }
      }
      getTrafficAds()
    }, [])

    useEffect(() => {
      if (
        trafficAd?.adSource === 'internal' &&
        trafficAd?.placement === 'BADAHAM_MISSION' &&
        internalAdDescriptionRef.current
      ) {
        backendApis.postLogBannerAdEvent({
          eventType: 'ad_impression',
          adId: trafficAd._id,
          adType: trafficAd.adType, // internal
          placement: trafficAd.placement,
          product: trafficAd.product,
          adSource: trafficAd.adSource,
          reward: 0,
        })
      }
    }, [trafficAd])

    const getButtonText = (mission) => {
      if (loadingStates[mission.questType]) return '처리중...'
      if (mission.questType === 'browsing' && !isEnableBrowsing)
        return `${String(remainingHours).padStart(2, '0')}:${String(
          remainingMinutes,
        ).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
      if (mission.questType === 'adWatch' && !mission.isActive)
        return '광고 준비중'

      if (mission.questType === 'internalAd') {
        return trafficAd?.actionText || '알아보기'
      }

      const missionType = mission.questType
      const limitCount = badahamConfig?.[missionType]?.limitCount
      const clearedCount = mission?.clearedCount || 0
      const isCompleted = clearedCount >= limitCount
      // if (isCompleted && missionType === 'adTreasureHuntComplete')
      //   return '다시하기'
      return isCompleted ? '완료' : `${limitCount - clearedCount}번가능`
    }

    const lastBrowsingClearedAt =
      dailyBadahamData?.questInfo?.browsing?.[
        dailyBadahamData?.questInfo?.browsing?.length - 1
      ]

    const browsingDuration = badahamConfig?.browsing?.duration

    const lastBrowsingClearedAtPlusDuration =
      new Date(lastBrowsingClearedAt).getTime() + browsingDuration
    const remainingTimeInMs = lastBrowsingClearedAtPlusDuration
      ? lastBrowsingClearedAtPlusDuration - currentTime
      : 0
    const remainingHours = Math.floor(remainingTimeInMs / (1000 * 60 * 60))
    const remainingMinutes = Math.floor(
      (remainingTimeInMs % (1000 * 60 * 60)) / (1000 * 60),
    )
    const remainingSeconds = Math.floor(
      (remainingTimeInMs % (1000 * 60)) / 1000,
    )
    const isEnableBrowsing =
      remainingHours <= 0 && remainingMinutes <= 0 && remainingSeconds <= 0

    const enableAdWatch = UserStore.isRewardAdReady
    const handleClickMissionButton = async ({
      limitCount,
      clearedCount,
      questType,
    }) => {
      if (
        // questType !== 'adTreasureHuntComplete' &&
        limitCount <= clearedCount ||
        loadingStates[questType]
      ) {
        return
      }

      AlfarmEventLogger({
        throttleRef,
        locationType: 'badahamScreenV2',
        locationName: `mission_list_${questType}`,
        eventType: 'click',
        collection: 'UserAlfarmClickLog',
      })

      try {
        switch (questType) {
          case 'dailyCheckIn':
            setLoadingStates((prev) => ({ ...prev, [questType]: true }))

            await backendApis.clearDailyEventQuest({
              event: 'badahamV2',
              questType,
            })
            refetchDailyBadahamData()
            refetchCoinCount()
            break
          case 'internalAd':
            appBridgeSender.goExternalBrowser(trafficAd?.actionLink)
            await backendApis.clearDailyEventQuest({
              event: 'badahamV2',
              questType,
            })
            backendApis.postLogBannerAdEvent({
              eventType: 'ad_click',
              adId: trafficAd._id,
              adType: trafficAd.adType, // internal
              placement: trafficAd.placement,
              product: trafficAd.product,
              adSource: trafficAd.adSource,
              reward: 0,
            })
            break
          case 'adWatch':
            if (!enableAdWatch) {
              break
            }
            ModalStore.setIsModalOpen('rewardAdModalWater')
            break
          case 'offerwallBrowsing':
            if (!isEnableOfferwallBrowsing) {
              break
            }
            const currentTime = new Date().getTime()
            navigate(
              `/offerwall?from=badahamV2&missionType=offerwallBrowsing&startTime=${currentTime}`,
            )
            break
          case 'offerwall':
            navigate('/offerwall?from=badahamV2')
            break
          case 'browsing': {
            if (
              !isEnableBrowsing &&
              UserStore.farmData?.userId !== '6565aa51ed116a90f986d306'
            ) {
              break
            }

            if (Math.random() < 0.5) {
              window.location.href = `#questRecommend.${JSON.stringify({
                screen: 'AlFarmQuestLinkRecommendItem',
                enteringComponent: 'Alfarm_BadahamBrowsing',
                timer: 30,
              })}`
            } else {
              window.location.href = `#questTimeDeal.${JSON.stringify({
                screen: 'AlFarmQuestLinkTimeDeal',
                enteringComponent: 'Alfarm_BadahamBrowsing',
                timeStamp: new Date(new Date().getTime() + 1000 * 30),
              })}`
            }
            break
          }

          case 'adTreasureHuntComplete':
            const questTimeDeal = {
              screen: 'AdTreasureHuntScreen',
            }

            window.location.href = `#adTreasureHunt.${JSON.stringify(
              questTimeDeal,
            )}`
            break
        }
      } catch (error) {
        console.error('미션 처리 실패:', error)
      } finally {
        setLoadingStates((prev) => ({ ...prev, [questType]: false }))
      }

      AlfarmEventLogger({
        throttleRef,
        locationType: 'page',
        locationName: 'badahamScreenV2',
        eventType: 'click',
        eventName: questType,
        data: {},
        collection: 'UserAlfarmClickLog',
      })
    }

    const missions = [
      {
        title: '출석하기',
        questType: 'dailyCheckIn',
        icon: '/ads/badahamMission/checkinIcon.png',
        description: (
          <div className='flex items-center gap-[8px]'>
            <div className='flex items-center justify-center'>
              <img
                src={BADAHAM_V2_COIN_IMAGE_URL}
                alt='coin'
                className='w-[16px] mr-[2px]'
              />
              <span className='text-[#888] text-[15px] font-light leading-[20px]'>
                1개
              </span>
            </div>
          </div>
        ),
        clearedCount: dailyBadahamData?.questInfo?.dailyCheckIn?.length || 0,
        limitCount: badahamConfig?.dailyCheckIn?.limitCount,
      },
      trafficAd?.adSource === 'internal' &&
        trafficAd?.placement === 'BADAHAM_MISSION' &&
        (dailyBadahamData?.questInfo?.internalAd?.length || 0) < 1 && {
          title: trafficAd?.title?.slice(0, 12),
          questType: 'internalAd',
          icon: trafficAd?.brandIconImg,
          description: (
            <div
              ref={internalAdDescriptionRef}
              className='flex items-center gap-[8px]'
            >
              <div className='flex items-center justify-center'>
                <img
                  src={BADAHAM_V2_COIN_IMAGE_URL}
                  alt='coin'
                  className='w-[16px] mr-[2px]'
                />
                <span className='text-[#888] text-[15px] font-light leading-[20px]'>
                  {`1개 | ${trafficAd?.subTitle ?? ''}`}
                </span>
              </div>
            </div>
          ),
          clearedCount: dailyBadahamData?.questInfo?.internalAd?.length ?? 0,
          limitCount: 1,
        },
      browsingCodepushAvailable
        ? {
            title: '상품 구경하기',
            questType: 'browsing',
            icon: 'https://assets.ilevit.com/c7fdd6aa-6831-492e-8078-ed36f32a9e85.png',
            description: (
              <div className='flex items-center gap-[2px]'>
                <div className='flex items-center justify-center'>
                  <img
                    src={BADAHAM_V2_COIN_IMAGE_URL}
                    alt='coin'
                    className='w-[16px] mr-[2px]'
                  />
                  <span className='text-[#888] text-[15px] font-light leading-[20px]'>
                    3개
                  </span>
                </div>
                <div className='flex items-center gap-[2px]'>
                  <div className='flex items-center justify-center'>
                    <img
                      src='/icon/water.png'
                      alt='water'
                      className='w-[16px]'
                    />
                    <span className='flex items-center text-[#888] text-[15px] font-light leading-[20px]'>
                      지급
                    </span>
                  </div>
                </div>
              </div>
            ),
            clearedCount: dailyBadahamData?.questInfo?.browsing?.length || 0,
            limitCount: badahamConfig?.browsing?.limitCount,
          }
        : null,
      // MEMO(민국): 애드몹 광고 유닛이 자꾸 죽는 문제가 있어 일단 비활성화합니다.
      codePushVersionAvailable(userCodePushVersion, '7.0.44') &&
      AB(UserStore.farmData?.userId, 'badahamAdTreasureHunt1128', [98]) === 'b'
        ? {
            title: '보물찾기',
            questType: 'adTreasureHuntComplete',
            icon: 'https://assets.ilevit.com/ef9a09bd-a148-41e9-8234-f42d9ba42c04.png',
            description: (
              <div className='flex items-center text-[15px] text-[#888888]'>
                <div className='flex items-center justify-center'>
                  <img
                    src={BADAHAM_V2_COIN_IMAGE_URL}
                    alt='coin'
                    className='w-[16px] mr-[2px]'
                  />
                  <span className='text-[#888] text-[15px] font-light leading-[20px]'>
                    1개
                  </span>
                </div>
                <div className='flex items-center'>
                  <img src='/icon/water.png' alt='' className='w-[16px]' />
                </div>
                <div className='w-[2px]' />
                지급
              </div>
            ),
            clearedCount:
              dailyBadahamData?.questInfo?.adTreasureHuntComplete?.length || 0,
            limitCount: badahamConfig?.adTreasureHuntComplete?.limitCount,
          }
        : null,
      !ABStore.admobTester ||
      (codePushVersionAvailable(userCodePushVersion, '7.0.27') &&
        ABStore.admobTester)
        ? {
            title: '광고보기',
            questType: 'adWatch',
            icon: '/ads/badahamMission/tvIcon.png',
            description: (
              <div className='flex items-center gap-[2px]'>
                <div className='flex items-center justify-center'>
                  <img
                    src={BADAHAM_V2_COIN_IMAGE_URL}
                    alt='coin'
                    className='w-[16px] mr-[2px]'
                  />
                  <span className='text-[#888] text-[15px] font-light leading-[20px]'>
                    3개
                  </span>
                </div>
                <div className='flex items-center'>
                  <div className='flex items-center justify-center'>
                    <img
                      src='/icon/water.png'
                      alt='water'
                      className='w-[16px]'
                    />
                  </div>
                  <span className='flex items-center text-[#888] text-[15px] font-light leading-[20px]'>
                    지급
                  </span>
                </div>
              </div>
            ),
            isActive: enableAdWatch,
            clearedCount: dailyBadahamData?.questInfo?.adWatch?.length || 0,
            limitCount: badahamConfig?.adWatch?.limitCount,
          }
        : null,
      {
        title: '퀘스트하고 보상받기',
        questType: 'offerwall',
        icon: 'https://assets.ilevit.com/be6a7a41-987f-4085-8534-7170d6e72f9c.png',
        description: (
          <div className='flex items-center gap-[2px]'>
            <div className='flex items-center justify-center'>
              <img
                src={BADAHAM_V2_COIN_IMAGE_URL}
                alt='coin'
                className='w-[16px] mr-[2px]'
              />
              <span className='text-[#888] text-[15px] font-light leading-[20px]'>
                5개
              </span>
            </div>
            <div className='flex items-center gap-[2px]'>
              <div className='flex items-center justify-center'>
                <img
                  src='/icon/fertilizer.png'
                  alt='fertilizer'
                  className='w-[16px]'
                />
                <img
                  src='/icon/fertilizerSet.png'
                  alt='fertilizerSet'
                  className='w-[16px]'
                />
                <img src='/icon/water.png' alt='water' className='w-[16px]' />
                <span className='flex items-center text-[#888] text-[15px] font-light leading-[20px]'>
                  지급
                </span>
              </div>
            </div>
          </div>
        ),
        clearedCount: dailyBadahamData?.questInfo?.offerwall?.length || 0,
        limitCount: badahamConfig?.offerwall?.limitCount,
      },
    ].filter(Boolean)

    return (
      <div className='w-full rounded-[12px] bg-[#FFF] px-[22.5px] py-[16px]'>
        {missions
          .sort((a, b) => {
            // MEMO(민국): 클리어한 미션 리스트에서 뒤로 미루기 위한 로직
            const aCompleted = a.clearedCount >= a.limitCount
            const bCompleted = b.clearedCount >= b.limitCount
            if (aCompleted && !bCompleted) return 1
            if (!aCompleted && bCompleted) return -1
            return 0
          })
          .map((mission) => (
            <div key={mission.title}>
              <div className='w-full flex items-center h-[7vh] justify-between'>
                <div className='flex items-center'>
                  <img src={mission.icon} alt='icon' className='w-12' />
                  <div className='flex flex-col h-full justify-center ml-2'>
                    <div className='text-[#000] text-[16px] font-bold leading-[20.762px]'>
                      {mission.title}
                    </div>
                    {mission.description}
                  </div>
                </div>
                <div
                  className='rounded-lg py-[10px] text-[18px] font-bold min-w-[82px] flex justify-center'
                  style={{
                    backgroundColor:
                      // mission.questType !== 'adTreasureHuntComplete' &&
                      mission.clearedCount >= mission.limitCount ||
                      loadingStates[mission.questType]
                        ? '#F3F3F3'
                        : ((mission.questType === 'adWatch' ||
                            mission.questType === 'offerwallBrowsing') &&
                            !mission.isActive) ||
                          (mission.questType === 'browsing' &&
                            !isEnableBrowsing)
                        ? 'transparent' // 광고 준비중일 때는 배경색 없음
                        : '#26C0DB',
                    color:
                      // mission.questType !== 'adTreasureHuntComplete' &&

                      mission.questType === 'browsing' && !isEnableBrowsing
                        ? '#5a5a5a'
                        : mission.clearedCount >= mission.limitCount ||
                          loadingStates[mission.questType]
                        ? '#8E8E8E'
                        : (mission.questType === 'adWatch' ||
                            mission.questType === 'offerwallBrowsing') &&
                          !mission.isActive
                        ? '#8E8E8E'
                        : '#FFF',
                    cursor:
                      loadingStates[mission.questType] ||
                      (mission.questType === 'adWatch' && !mission.isActive)
                        ? 'not-allowed'
                        : 'pointer',
                  }}
                  onClick={() => {
                    handleClickMissionButton({
                      limitCount: mission.limitCount,
                      clearedCount: mission.clearedCount,
                      questType: mission.questType,
                    })
                  }}
                >
                  {getButtonText(mission)}
                </div>
              </div>
              {missions.indexOf(mission) !== missions.length - 1 && (
                <div className='w-full h-[1px] my-[10px] bg-[#F6F6FA]'></div>
              )}
            </div>
          ))}
      </div>
    )
  },
)

export default MissionList
