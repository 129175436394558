import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import PlantBasicInfo from '../molecules/PlantBasicInfo'
import { farmingListOngoing } from '../../config'
import ModalStore from '../../store/ModalStore'

const PlantButton = ({
  plantName,
  setSelectedPlant,
  setImageSource,
  selectedPlant,
}) => {
  let chosenImageSource = `/badge/${plantName}.png`
  let weight = '2kg'
  weight = `${PlantBasicInfo?.PlantNameAndAmount(plantName)?.koreanName} ${
    PlantBasicInfo?.PlantNameAndAmount(plantName)?.amount
  }`

  if (plantName === 'empty') {
    return (
      <div
        key={plantName}
        style={{
          flex: '1 0 35%',
          border: '4px solid #EDEDED',
          margin: '4px',
          borderRadius: '8px',
        }}
        onClick={() => {
          setSelectedPlant(plantName)
          setImageSource(chosenImageSource)
        }}
      />
    )
  }

  return (
    <div
      className='relative'
      key={plantName}
      style={
        selectedPlant === plantName
          ? {
              flex: '1 0 35%',
              border: '4px solid rgba(255,164,0,1)',
              margin: '4px ',
              borderRadius: '8px',
            }
          : {
              flex: '1 0 35%',
              border: '4px solid #EDEDED',
              margin: '4px',
              borderRadius: '8px',
            }
      }
      onClick={() => {
        setSelectedPlant(plantName)
        setImageSource(chosenImageSource)
      }}
    >
      {plantName === 'coffee_starbucks' && (
        <img
          style={{
            position: 'absolute',
            width: '9vw',
            right: '6vw',
            top: '2vw',
          }}
          src={`/icon/starbucks.png`}
          alt=''
        />
      )}
      {plantName === 'coffee_compose' && (
        <img
          style={{
            position: 'absolute',
            width: '9vw',
            right: '6vw',
            top: '2vw',
            borderRadius: 99,
          }}
          src={`/icon/compose.png`}
          alt=''
        />
      )}
      {plantName === 'coffee_mega' && (
        <img
          style={{
            position: 'absolute',
            width: '9vw',
            right: '6vw',
            top: '2vw',
          }}
          src={`/icon/mega.png`}
          alt=''
        />
      )}

      {[
        'coffee_mega',
        'coffee_compose',
        'ramen',
        'pokachip',
        'mychew',
      ]?.includes(plantName) && (
        <img
          style={{
            position: 'absolute',
            width: '18vw',
            left: '10vw',
            top: '0vw',
          }}
          src={`/icon/easyTag.png`}
          alt=''
        />
      )}

      <div
        style={{
          color: 'black',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '8px 0px 0px 0px',
        }}
      >
        <img
          style={{
            width: '80%',
            background: 'white',
          }}
          src={chosenImageSource}
          alt=''
        />
      </div>
      <div
        style={{
          color: 'black',
          // position: "absolute",
          // border: "1px solid black",
          fontFamily: 'mapleStory',
          textAlign: 'center',
          marginBottom: '4px',
          fontSize: '0.8rem',
        }}
      >
        {weight}
      </div>
    </div>
  )
}

const ChangePlantModal = observer(
  ({ token, farmData, setFarmData, itemType }) => {
    const [plantList, setPlantList] = useState(farmingListOngoing)
    const [isChangeable, setIsChangeable] = useState()
    const [buttonText, setButtonText] = useState('')
    const [selectedPlant, setSelectedPlant] = useState()
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [imageSource, setImageSource] = useState('')
    const [infoModal, setInfoModal] = useState(false)
    const [infoModal2, setInfoModal2] = useState(false)

    async function getChangePlantData() {
      let result = await backendApis.getChangePlantData(token)
      setIsChangeable(result?.isChangePlantAvailable)

      if (!itemType || !farmData?.farmLevel) {
        return
      }
      if (
        itemType === 'mandarin' ||
        itemType === 'persimmon' ||
        itemType === 'pear' ||
        itemType === 'apple' ||
        itemType === 'lime' ||
        itemType === 'pineapple'
      ) {
        setButtonText('작물 변경하기')
      } else if (result?.isChangePlantAvailable) {
        setButtonText('작물 변경하기')
      } else {
        setButtonText('새 친구 초대하고 변경하기')
      }
    }

    function shareKakao() {
      window.location.href = '#alfarmChangePlantInvite'
    }

    const updateMandarinChangedToOtherPlant = async () => {
      const mandarinChange =
        itemType === 'mandarin' &&
        // Number(farmData?.farmLevel?.level6) > 60000 &&
        !farmData?.didChangePlantFromMandarinToOther

      if (mandarinChange && !farmData?.didChangePlantFromMandarinToOther) {
        setFarmData({
          ...farmData,
          didChangePlantFromMandarinToOther: new Date(),
        })
        await backendApis.updatelastUpdatedAt(token, 'PUT', {
          updateType: 'didChangePlantFromMandarinToOther',
        })
      }
    }
    const updatePersimmonChangedToOtherPlant = async () => {
      const persimmonChange =
        itemType === 'persimmon' &&
        // Number(farmData?.farmLevel?.level6) > 60000 &&
        !farmData?.didChangePlantFromPersimmonToOther

      const appleChange =
        itemType === 'apple' &&
        // Number(farmData?.farmLevel?.level6) > 60000 &&
        !farmData?.didChangePlantFromAppleToOther

      const limeChange =
        itemType === 'lime' && !farmData?.didChangePlantFromLimeToOther

      const pineappleChange =
        itemType === 'pineapple' &&
        !farmData?.didChangePlantFromPineappleToOther

      if (persimmonChange) {
        setFarmData({
          ...farmData,
          didChangePlantFromPersimmonToOther: new Date(),
        })
        await backendApis.updatelastUpdatedAt(token, 'PUT', {
          updateType: 'didChangePlantFromPersimmonToOther',
        })
      }

      if (appleChange) {
        setFarmData({
          ...farmData,
          didChangePlantFromAppleToOther: new Date(),
        })
        await backendApis.updatelastUpdatedAt(token, 'PUT', {
          updateType: 'didChangePlantFromAppleToOther',
        })

        if (limeChange) {
          setFarmData({
            ...farmData,
            didChangePlantFromLimeToOther: new Date(),
          })
          await backendApis.updatelastUpdatedAt(token, 'PUT', {
            updateType: 'didChangePlantFromLimeToOther',
          })
        }

        if (pineappleChange) {
          setFarmData({
            ...farmData,
            didChangePlantFromPineappleToOther: new Date(),
          })
          await backendApis.updatelastUpdatedAt(token, 'PUT', {
            updateType: 'didChangePlantFromPineappleToOther',
          })
        }
      }
    }

    async function changePlant() {
      if (selectedPlant === itemType) {
        if (
          !(
            itemType === 'mandarin' &&
            farmData.didChangePlantFromMandarinToOther
          )
        ) {
          setInfoModal2(true)
          setTimeout(() => {
            setInfoModal2(false)
          }, 3000)
          setOpenConfirmModal(false)
          return
        }
        if (
          !(
            itemType === 'persimmon' &&
            !farmData.didChangePlantFromPersimmonToOther
          )
        ) {
          setInfoModal2(true)
          setTimeout(() => {
            setInfoModal2(false)
          }, 3000)
          setOpenConfirmModal(false)
          return
        }
        if (!(itemType === 'pear' && !farmData.didChangePlantFromPearToOther)) {
          setInfoModal2(true)
          setTimeout(() => {
            setInfoModal2(false)
          }, 3000)
          setOpenConfirmModal(false)
          return
        }

        if (
          !(itemType === 'apple' && !farmData.didChangePlantFromAppleToOther)
        ) {
          setInfoModal2(true)
          setTimeout(() => {
            setInfoModal2(false)
          }, 3000)
          setOpenConfirmModal(false)
          return
        }

        if (
          !(
            itemType === 'pineapple' &&
            !farmData.didChangePlantFromPineappleToOther
          )
        ) {
          setInfoModal2(true)
          setTimeout(() => {
            setInfoModal2(false)
          }, 3000)
          setOpenConfirmModal(false)
          return
        }
      }
      if (!selectedPlant) {
        setInfoModal(true)
        setTimeout(() => {
          setInfoModal(false)
        }, 3000)

        return
      }
      let result = await backendApis.changePlant(token, 'PUT', {
        itemType: selectedPlant,
      })
      if (result.status === 200) {
        updateMandarinChangedToOtherPlant()
        updatePersimmonChangedToOtherPlant()

        ModalStore.setIsAutoAppearModal(false)
        window.location.href = `/farmPage?token=${token}&itemType=${selectedPlant}`
      }
    }

    useEffect(() => {
      getChangePlantData()
    }, [itemType, farmData?.farmLevel?.level6])

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 1000,
            width: '100%',
            height: '100vh',
            background: 'rgba(0,0,0,0.8)',
          }}
        />
        <div
          style={{
            height: '130vw',
            position: 'absolute',
            zIndex: 1001,
            background: 'white',
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 4px 0px 4px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            width: '90vw',
          }}
        >
          {itemType !== 'apple' &&
            itemType !== 'pear' &&
            itemType !== 'mandarin' &&
            !farmData.didChangePlantFromMandarinToOther &&
            itemType !== 'persimmon' &&
            !farmData?.didChangePlantFromPersimmonToOther &&
            itemType !== 'pineapple' &&
            !farmData?.didChangePlantFromPineappleToOther && (
              <div
                style={{
                  top: '-12vw',
                  width: '8%',

                  right: '0',
                  zIndex: 1005,
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  ModalStore.setIsModalOpen('basic')
                }}
              >
                <img src='/icon/modalCloseButton.png' alt='' />
              </div>
            )}
          <div
            style={{
              fontFamily: 'maplestory',
              color: '#402C24',
              fontSize: 22,
              textAlign: 'center',
              marginTop: '5vw',
              marginBottom: '1vw',
            }}
          >
            작물 변경
          </div>
          <div
            style={{
              fontFamily: 'maplestory',
              color: '#402C24',
              textAlign: 'center',
              marginTop: '1vw',
              fontSize: '4vw',
            }}
          >
            경험치, 물, 비료 그대로 작물을 바꿔보세요
          </div>
          <div
            style={{
              fontFamily: 'maplestory',
              color: '#888',
              fontSize: '3vw',
              textAlign: 'center',
            }}
          >
            *작물 난이도에 따라 레벨이 달라질 수 있어요
          </div>
          <div
            className='h-[34vh]'
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              position: 'relative',
              overflowY: 'scroll',

              margin: '16px 12px 16px 12px',
              // border: "1px solid black",
            }}
          >
            {plantList
              .filter((i) => i !== itemType)
              .map((plantName) => (
                <PlantButton
                  key={Math.random()}
                  selectedPlant={selectedPlant}
                  setSelectedPlant={setSelectedPlant}
                  setImageSource={setImageSource}
                  plantName={plantName}
                />
              ))}

            {plantList.filter((i) => i !== itemType)?.length % 2 === 1 && (
              <PlantButton
                key={Math.random()}
                selectedPlant={selectedPlant}
                setSelectedPlant={setSelectedPlant}
                setImageSource={setImageSource}
                plantName={'empty'}
              />
            )}
          </div>
          <button
            style={{
              background:
                'linear-gradient(180deg, rgba(255,211,0,1) 0%, rgba(255,164,0,1) 100%)',
              margin: '0px',
              padding: '4vw 12vw 4vw 12vw',
              borderRadius: '100vw',
              marginBottom: '4vw',
              width: '70vw',
              fontSize: buttonText?.length > 6 ? '4vw' : '5vw',
              fontFamily: 'maplestory',
              color: 'black',
            }}
            onClick={() => {
              if (!selectedPlant) {
                setInfoModal(true)
                setTimeout(() => {
                  setInfoModal(false)
                }, 3000)

                return
              }
              if (
                itemType === 'mandarin' ||
                itemType === 'persimmon' ||
                itemType === 'pear' ||
                itemType === 'apple' ||
                itemType === 'lime' ||
                itemType === 'pineapple'
              ) {
                setOpenConfirmModal(true)
              } else {
                if (isChangeable && selectedPlant) {
                  setOpenConfirmModal(true)
                } else {
                  shareKakao()
                }
              }
            }}
          >
            {buttonText}
          </button>
        </div>

        {openConfirmModal && (
          <div
            style={{
              position: 'absolute',
              top: '0px',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.8)',
              zIndex: 1011,
            }}
          >
            <div
              style={{
                position: 'absolute',
                height: '80vw',
                width: '70vw',
                borderRadius: '8px',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '16px 0px 16px 0px',
                }}
              >
                <div
                  style={{
                    color: 'black',
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    fontFamily: 'maplestory',
                    fontSize: '5vw',
                    margin: '0px 8px 0px 8px',
                  }}
                >
                  해당 작물로 바꾸시겠어요?
                </div>

                <img
                  style={{
                    width: '40vw',
                    background: 'white',
                    marginTop: '4vw',
                  }}
                  src={imageSource}
                  alt=''
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    border: '2px solid rgba(255,164,0,1)',
                    color: 'black',
                    margin: '4vw',
                    padding: '3vw 6vw 3vw 6vw',
                    marginBottom: '8vw',
                    fontFamily: 'maplestory',
                    borderRadius: '2vw',
                    fontSize: '4vw',
                  }}
                  onClick={() => setOpenConfirmModal(false)}
                >
                  취소
                </div>
                <div
                  style={{
                    margin: '4vw',
                    padding: '3vw 6vw 3vw 6vw',
                    marginBottom: '8vw',
                    fontFamily: 'maplestory',
                    borderRadius: '2vw',
                    backgroundColor: 'rgba(255,164,0,1)',
                    border: '2px solid rgba(255,164,0,1)',
                    color: 'black',
                    fontSize: '4vw',
                  }}
                  onClick={() => {
                    changePlant()
                  }}
                >
                  확인
                </div>
              </div>
            </div>
          </div>
        )}
        {infoModal && (
          <div
            style={{
              flex: 1,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              zIndex: 2000,
              width: '80%',
              height: '30vw',
              background: 'rgba(0,0,0,0.8)',
              borderRadius: '4vw',
              fontFamily: 'maplestory',
              fontSize: '4.5vw',
              textAlign: 'center',
              lineHeight: '180%',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            선택된 작물이 없어요 <br />
            작물을 선택해주세요
          </div>
        )}
        {infoModal2 && (
          <div
            style={{
              flex: 1,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              zIndex: 2000,
              width: '80%',
              height: '30vw',
              background: 'rgba(0,0,0,0.8)',
              borderRadius: '4vw',
              fontFamily: 'maplestory',
              fontSize: '4.5vw',
              textAlign: 'center',
              lineHeight: '180%',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            현재 키우고 있는 작물이에요 <br />
            다른 작물을 선택해주세요
          </div>
        )}
      </>
    )
  },
)

export default ChangePlantModal
