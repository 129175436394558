import React, { useState } from 'react'

const textMap = {
  invitorFull: {
    firstText: '하루에 3번까지만 참여할 수 있어요',
    secondText: '내일 다시 참여해주세요',
  },
  inviteFull: {
    firstText: '친구가 이미 3명의 친구를 초대했어요',
    secondText: '내일 다시 초대를 받을 수 있어요',
  },
  selfInvite: {
    firstText: '스스로 초대할 수 없어요',
    secondText: '다른 친구를 초대해주세요',
  },
  alreadyInvited: {
    firstText: '이미 초대를 받은 친구예요',
    secondText: '다른 친구의 초대를 받아보세요',
  },
  alreadyInvite: {
    firstText: '이미 초대를 받은 친구예요',
    secondText: '다른 친구의 초대를 받아보세요',
  },
  noUserData: {
    firstText: '친구 정보를 확인할 수 없어요',
    secondText: '초대 링크를 확인해주세요',
  },
  noInvitorData: {
    firstText: '친구 정보를 확인할 수 없어요',
    secondText: '초대 링크를 확인해주세요',
  },
  success: {
    firstText: '초대 받아줘서 고마워요',
    secondText: '물 30g을 받았어요',
  },
  gganbuInviteFull: {
    firstText: '친구가 이미 20명과 맞팜을 하고 있어요',
    secondText: '다른 친구와 맞팜을 해보세요',
  },
  gganbuInvitorFull: {
    firstText: '내가 이미 20명과 맞팜을 하고 있어요',
    secondText: '친구를 삭제하고 초대해보세요',
  },
  gganbuSelfInvite: {
    firstText: '스스로 초대할 수 없어요',
    secondText: '다른 친구를 초대해보세요',
  },
  gganbuAlreadyInvited: {
    firstText: '이미 초대한 친구예요',
    secondText: '다른 친구를 초대해보세요',
  },
  gganbuAlreadyInvite: {
    firstText: '이미 초대한 친구예요',
    secondText: '다른 친구를 초대해보세요',
  },
  gganbuNoUserData: {
    firstText: '친구 정보를 확인할 수 없어요',
    secondText: '다른 친구를 초대해보세요',
  },
  gganbuNoInvitorData: {
    firstText: '친구 정보를 확인할 수 없어요',
    secondText: '다른 친구를 초대해보세요',
  },
  gganbuSuccess: {
    firstText: '맞팜 초대를 받았어요',
    secondText: '맞팜 친구 농장에 방문해보세요',
  },
  AlreadyInvited: {
    firstText: '친구 초대 참여는 한번만 가능해요',
    secondText: '다음에 다시 참여해주세요',
  },
  AlreadyRegistered: {
    firstText: '친구 초대는 새친구만 참여할 수 있어요',
    secondText: '',
  },
  leftUser: {
    firstText: '초대 참여에 실패했어요',
    secondText: '탈퇴 고객은 새친구 초대에 참여할 수 없어요',
  },
  leftUser2: {
    firstText: '초대 참여에 실패했어요',
    secondText: '탈퇴일로부터 7일간 참여가 불가해요',
  },
  dailyLotteryEarlyInvite: {
    firstText: '타임추첨은 오후 7시에서',
    secondText: '오후 8시 사이에만 참가하실 수 있어요',
  },
  dailyLotteryInvitorAlreadyTeamed: {
    firstText: '상대방이 이미 팀을 맺었어요',
    secondText: '다른 친구를 찾아보세요',
  },
  dailyLotterySelfInvite: {
    firstText: '스스로는 초대할 수 없어요',
    secondText: '친구와 함께 팀을 맺어보세요',
  },
  successLuckyPiggy: {
    firstText: '초대 받아줘서 고마워요',
    secondText: '친구가 코인을 받았어요',
  },
  inviteFullLuckyPiggy: {
    firstText: '하루에 3번까지 초대를 받을 수 있어요',
    secondText: '내일 또는 다른 친구와 함께 해보세요',
  },
  selfInviteLuckyPiggy: {
    firstText: '스스로 초대는 불가능해요',
    secondText: '친구를 초대해보세요',
  },
  alreadyInviteLuckyPiggy: {
    firstText: '이미 초대에 참여했어요',
    secondText: '다른 친구의 초대도 받아보세요',
  },
  noDataLuckyPiggy: {
    firstText: '친구 정보 확인에 실패했어요',
    secondText: '초대 링크를 확인해주세요',
  },
  basicModal: {
    firstText: '초대 받아줘서 고마워요',
    secondText: '작물을 키워 볼까요?',
  },
  drinkWaterModal: {
    firstText: '잊지말고 물 마시세요',
    secondText: '우리 함께 건강해져요',
  },
  successResurrect: {
    firstText: '다시 돌아와줘서 고마워요',
    secondText: '물을 주면 고급비료를 받아요',
  },
  alreadyResurrected: {
    firstText: '다시 돌아와줘서 고마워요',
    secondText: '작물에 물을 줘볼까요?',
  },
  errorAlert: {
    firstText: '오류가 발생했어요',
    secondText: '잠시 후 다시 시도해 주세요',
  },
  nameLength: {
    firstText: '이름짓기에 실패했어요',
    secondText: '이름은 한글자 이상 입력해야해요',
  },
  friendGardenFull: {
    firstText: '텃밭이 꽉 찼어요',
    secondText: '텃밭이 비워져야 초대가 적용되요',
  },
  friendGardenNotNewUser: {
    firstText: '새친구가 아니에요',
    secondText: '새친구 이벤트 참여가 불가능해요',
  },
  serverError: {
    firstText: '서버 오류가 발생했어요',
    secondText: '잠시 후 다시 시도해주세요',
  },
  alreadyHarvested: {
    firstText: '최근 수확 횟수를 초과했어요',
    secondText: '고객센터로 문의해 주세요',
  },
  newVersionAppForHarvest: {
    firstText:
      '작물 수확을 위해 스토어에서 앱을 최신 버전으로 업데이트 해주세요',
    secondText: '',
  },
  churnedGganbuBountySuccess: {
    firstText: '친구에게 보상이 지급되었어요!',
    secondText: '',
  },
  churnedGganbuBountyFail: {
    firstText: '친구의 현상금 대상이 아니에요!',
    secondText: '',
  },
  churnedGganbuBountyFail2: {
    firstText: '현상금 보상 대상이 아니에요!',
    secondText: '',
  },
  churnedGganbuBountyFail3: {
    firstText: '현상금 시간제한이 지났어요!',
    secondText: '',
  },
  teamAlfarmSuccess: {
    firstText: '팀농장 안내',
    secondText: '팀에 성공적으로 합류했어요!',
  },
  teamAlfarmAlreadyTeam: {
    firstText: '팀농장 안내',
    secondText: '이미 팀에 합류한 상태에요!',
  },
  teamAlfarmTeamFull: {
    firstText: '팀농장 안내',
    secondText: '팀이 가득 차서 합류할 수 없어요!',
  },
  doubleExpSuccess: {
    firstText: '초대 성공!',
    secondText: '7일간 경험치가 2배로 올라요!',
  },
  mustStartWith010: {
    firstText: '맞팜 실패',
    secondText: '친구의 휴대폰 번호가 010으로 시작해야해요!',
  },
  notRealUser: {
    firstText: '맞팜 실패',
    secondText: '존재하지 않는 유저입니다.',
  },
}

const AlertModal = ({
  enteringRoute,
  setAlertModal,
  getGganbuList,
  setInviteResultStatus,
  onClick = () => {},
  setAlertModalOnClick = () => {},
}) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  if (enteringRoute) {
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: '0',
            right: '0',
            zIndex: 30000,
          }}
        >
          <div
            style={{
              backgroundColor: 'black',
              position: 'absolute',
              overlay: {
                background: 'rgba(0,0,0,0)',
              },
              width: '100%',
              height: '100%',
              opacity: '0.8',
            }}
          />
          <div
            style={{
              background:
                'linear-gradient(180deg, #FFF0BB 0%, #FFE176 3.65%, #FFBC39 94.79%, #F99219 100%)',
              width: '70vw',
              zIndex: 110,
              borderRadius: '4vw',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              padding: '4vw',
            }}
          >
            <div
              style={{
                color: '#402C24',
                fontFamily: 'maplestory',
                fontSize: '4.5vw',
                zIndex: 30001,
                textAlign: 'center',
                lineHeight: '150%',
                background: 'white',
                borderRadius: '2vw',
                padding: '4vw',
                flexDirection: 'column',
                display: 'flex',
                wordBreak: 'keep-all',
                wordWrap: 'break-word',
              }}
            >
              {textMap[enteringRoute]?.firstText ?? '오류가 발생했어요'}
              <br />
              {textMap[enteringRoute]?.secondText ?? '재접속을 시도해주세요'}
              <button
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  width: '45%',
                  height: '14vw',
                  marginTop: '8%',
                  zIndex: 999,
                  borderRadius: '444vw',
                  background:
                    'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
                  fontFamily: 'maplestory',
                  color: '#402C24',
                  fontSize: '4vw',
                  marginLeft: '27.5%',
                  marginBottom: '0vw',
                }}
                onClick={() => {
                  setAlertModal(false)
                  setInviteResultStatus('')
                  if (enteringRoute === 'success') {
                    getGganbuList()
                  }
                  onClick()
                  setAlertModalOnClick(() => {})
                }}
              >
                <div
                  style={{
                    color: '#402C24',
                    fontFamily: 'maplestory',
                    fontSize: '5vw',
                  }}
                >
                  확인
                </div>
              </button>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return <></>
  }
}

export default AlertModal
