import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import backendApis from '../utils/backendApis'
import commaNumber from 'comma-number'
import AuthStore from '../store/AuthStore'
import UserStore from '../store/UserStore'
import BasicButton from 'comps/atoms/basicButton'
import WaterEffect from 'comps/atoms/waterEffect'
import Modal from 'react-modal'
import TeamAlfarmExplainModal from 'comps/atoms/TeamAlfarmExplainModal'
import ResourceButton from 'comps/atoms/resourceButton'
import BottomSheetStore from 'store/BottomSheetStore'
import ToastStore from 'store/ToastStore'
import moment from 'moment'
import LoadingIndicator from 'comps/loadingIndicator'
import { toJS } from 'mobx'
import TeamAlfarmSelectItemTypeModal from 'comps/atoms/TeamAlfarmSelectItemTypeModal'
import TeamAlfarmPlant from 'comps/molecules/TeamAlfarmPlant'
import TeamAlfarmBasicModal from 'comps/atoms/TeamAlfarmBasicModal'
import TeamAlfarmQuitModal from 'comps/atoms/TeamAlfarmQuitModal'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import ModalStore from 'store/ModalStore'
import DoubleExpEventModal from 'comps/atoms/DoubleExpEventModal'
import { observer } from 'mobx-react-lite'

const TeamAlfarm = observer(() => {
  const codePushVersionAvailable = useCheckCodePushVersion()

  const [tutorialOn, setTutorialOn] = useState(false)
  const [animState, setAnimState] = useState('idle')
  const [waterModalVisible, setWaterModalVisible] = useState(false)
  const [canClickGiveWater, setCanClickGiveWater] = useState(true)
  const [indexCount, setIndexCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [openSelectItemModal, setOpenSelectItemModal] = useState(false)
  const [showlevelUpRewardModal, setShowlevelUpRewardModal] = useState(false)
  const [showQuitModal, setShowQuitModal] = useState(false)
  const [showHarvestModal, setShowHarvestModal] = useState(false)
  const [showHarvestWaitModal, setShowHarvestWaitModal] = useState(false)
  const [isGameEnd, setIsGameEnd] = useState(false)

  const navigate = useNavigate()
  const token = useSearchParam('token')

  useEffect(() => {
    const explainAutoPop = localStorage.getItem('teamAlfarmExplainAutoPop')
    if (!explainAutoPop) {
      setTutorialOn(true)
      localStorage.setItem('teamAlfarmExplainAutoPop', 'true')
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const result = await backendApis.getTeamAlfarmData(token)
      if (result?.status === 200) {
        UserStore?.set('teamAlfarmData', result?.data)
        if (result?.data?.myData?.level > 6) {
          setIsGameEnd(true)
          // 팀원들도 다 마지막 레벨임?
          if (
            result?.data?.members
              ?.filter((item) => item)
              ?.every((item) => item?.level > 6)
          ) {
            if (result?.data?.myData?.leader) {
              setShowHarvestModal(true)
            } else {
              setShowHarvestWaitModal(true)
            }
          } else {
            setShowHarvestWaitModal(true)
          }
        }
      } else {
        //
        alert('팀농장 데이터를 불러오는데 실패했습니다.')
        navigate(
          `../farmPage?token=${AuthStore?.token}&itemType=${UserStore?.itemType}`,
        )
      }
      setLoading(false)
    }

    fetchData()
  }, [])

  const handleAnim = (action, duration, callback) => {
    setWaterModalVisible(true)
    setAnimState(action)
    setTimeout(() => {
      callback()
    }, duration)
  }

  const getLevel = async (exp) => {
    const currentLevel = UserStore?.teamAlfarmData?.myData?.level

    const level1 = UserStore?.teamAlfarmData?.levelData?.level1
    const level2 = UserStore?.teamAlfarmData?.levelData?.level2
    const level3 = UserStore?.teamAlfarmData?.levelData?.level3
    const level4 = UserStore?.teamAlfarmData?.levelData?.level4
    const level5 = UserStore?.teamAlfarmData?.levelData?.level5
    const level6 = UserStore?.teamAlfarmData?.levelData?.level6

    const levelData = [level1, level2, level3, level4, level5, level6]

    let level = 1
    let remainExp = 0
    let expToLevelUp = 0
    let prev = 0
    for (let i = 0; i < levelData.length; i++) {
      const each = levelData[i]
      if (exp > each) level++
      else {
        //레벨카운팅 종료 하고 remainExp, expToLevelUp계산
        expToLevelUp = each - exp
        remainExp = exp - prev
        break
      }
      prev = each
    }
    UserStore?.set('teamAlfarmData.myData', {
      ...UserStore?.teamAlfarmData?.myData,
      exp,
      level,
      remainExp,
      expToLevelUp,
    })

    if (currentLevel < level) {
      // 레벨업 보상 주기 및 모달 팝업
      if (level <= 6) {
        setTimeout(() => {
          setShowlevelUpRewardModal(true)
        }, 1200)
      } else {
        window.location.reload()
      }
    }
  }

  const giveWaterBasketToTeamAlfarm = async (giveWaterCount = 1) => {
    if (!canClickGiveWater) return

    if ((UserStore?.farmData?.water ?? 0) < giveWaterCount * 10) {
      ToastStore.toastOn({
        type: 'error',
        message: '물이 부족해요!',
        duration: 2000,
      })
      return
    }
    setCanClickGiveWater(false)
    const result = await backendApis.giveWaterToTeamAlfarm(token, 'POST', {
      giveWaterCount,
      remainExp: UserStore?.teamAlfarmData?.myData?.remainExp,
    })

    if (result?.status === 200) {
      setIndexCount(indexCount + 1)
      // 매일 물 준 횟수 카운트 for daily mission v2
      let newDateTime = moment().add(-4, 'hours')
      let newDateFormatted = newDateTime.format('YYYY-MM-DD')
      let count = 0
      let storedData = localStorage.getItem('alfarm_giveWaterCount')

      if (storedData) {
        let parsedData = JSON.parse(storedData)

        if (parsedData.date === newDateFormatted) {
          count = parsedData.count
        }
      }
      localStorage.setItem(
        'alfarm_giveWaterCount',
        JSON.stringify({
          date: newDateFormatted,
          count: count + giveWaterCount,
        }),
      )
      // 매일 물 준 횟수 카운트 for daily mission v2 end

      setIndexCount(indexCount + 1)

      let tmpFarmData = UserStore?.farmData
      tmpFarmData.giveWaterCount += giveWaterCount
      tmpFarmData.water -= giveWaterCount * 10
      tmpFarmData.nutriment -= result?.data?.fert

      // UserStore?.set('farmData', tmpFarmData)

      // exp
      getLevel(UserStore?.teamAlfarmData?.myData?.exp + result?.data?.exp)

      handleAnim('giveWaterBasket', 1200, () => {
        setCanClickGiveWater(true)
        setAnimState('')
        setWaterModalVisible(false)
      })
    } else if (result?.status === 401) {
      ToastStore.toastOn({
        type: 'error',
        message: '물이 부족해요!',
        duration: 2000,
      })
      return
    }
  }

  const invite = () => {
    const inviteData = {
      shareType: 'AlfarmTeamAlfarmInvite',
      title: '[올팜] 팀농장으로 함께 올팜 작물을 키워봐요!',
      description: '무료 작물 수확이 훨씬 빠른 팀농장으로 초대해요!',
      bannerImage:
        'https://assets.ilevit.com/e9013127-af44-48f9-94af-759ead31f5a1.png',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
  }

  const quitTeam = async () => {
    const result = await backendApis.quitTeam({
      teamId: UserStore?.teamAlfarmData?.myData?.teamId,
    })
    if (result?.status === 200) {
      // 그만두기 성공 모달
      setShowQuitModal(false)
      // 클릭시 홈으로 가기
      navigate(
        `../farmPage?token=${AuthStore?.token}&itemType=${UserStore?.itemType}`,
      )
    } else {
      alert('팀농장 탈퇴에 실패했습니다.')
    }
  }

  const harvestData = async (link) => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'harvest',
        data: {
          itemPrice: UserStore?.teamAlfarmData?.itemPrice,
        },
      }),
    )
    window.location.href = `${link}&key=${AuthStore.key}`
  }

  // 컴포넌트들

  const QuickButton = ({
    action = 'goBack',
    onClick,
    className = '',
    style,
    src = '/icon/goBack.png',
  }) => {
    return (
      <button
        onClick={onClick}
        className={`bt-gganbu-quick ${className}`}
        style={style}
      >
        <img src={src} alt='' />
      </button>
    )
  }

  if (loading) {
    return <LoadingIndicator isLoading={loading} />
  }

  return (
    <div
      className='w-full h-full fixed flex flex-col overflow-y-scroll overflow-x-hidden'
      style={{
        backgroundImage: 'url(../teamAlfarm/background.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <header className='w-full mt-[10vw] flex flex-row justify-between items-center mb-4 relative'>
        <NavLink
          to={`/farmPage?token=${AuthStore?.token}&itemType=${
            UserStore?.itemType
          }&randomNumber=${Math.random().toString().slice(0, 8)}`}
        >
          <button className='w-[7vw] ml-[2vw] z-[999] flex-1' action='goBack'>
            <img src='/icon/Back.png' alt='back button' />
          </button>
        </NavLink>
        <div
          className='font-bold px-[4vw] py-[1vw] -mr-[1.5vw] bg-[rgba(25,25,25,0.5)] rounded-[2vw] text-white'
          onClick={() => setTutorialOn(true)}
        >
          참여방법
        </div>
        {UserStore?.teamAlfarmData?.members?.filter((item) => item)?.length >
          0 && (
          <div
            className='absolute font-bold px-[4vw] py-[1vw] -mr-[1.5vw] bg-[rgba(25,25,25,0.5)] rounded-[2vw] text-white right-0 top-[12vw]'
            onClick={() => setShowQuitModal(true)}
          >
            그만두기
          </div>
        )}
      </header>
      <div className='flex-1 flex flex-col justify-center items-center'>
        <div className='flex flex-row w-full justify-around items-center -mb-[42vw] mt-[12vw]'>
          {UserStore?.teamAlfarmData?.members?.map((member, index) => {
            return (
              <TeamAlfarmPlant
                key={member?.userId || Math.random()}
                data={member}
                active={member?.teamId}
                invite={invite}
                animState={animState}
                token={token}
                teamAlfarmData={UserStore?.teamAlfarmData}
              />
            )
          })}
        </div>
        <div className='w-full'>
          <TeamAlfarmPlant
            myPlant={true}
            data={UserStore?.teamAlfarmData?.myData}
            active={UserStore?.teamAlfarmData?.myData?.teamId}
            invite={invite}
            animState={animState}
            token={token}
            teamAlfarmData={UserStore?.teamAlfarmData}
          />
        </div>

        {/* 양분 표기 */}
        {!isGameEnd && UserStore?.teamAlfarmData?.itemType && (
          <div className='absolute w-full h-[30vw] pt-[40vw]'>
            <ResourceButton
              action='fertilizerResource'
              label={
                UserStore?.farmData?.nutriment === undefined
                  ? 1
                  : UserStore?.farmData?.nutriment
              }
              onClick={() => {
                BottomSheetStore.setIsbottomSheetOpen('fertilizer')
              }}
              giveWaterCount={3000}
            />
          </div>
        )}

        {/* 좌상단 */}
        <div className='flex flex-col justify-start items-center absolute left-[4vw] top-[30vw]'>
          {/* <QuickButton
            onClick={() => {
              ModalStore.setIsModalOpen('teamAlfarmCheckIn')
            }}
            src='../teamAlfarm/leftTopScrollButton/teamCheckIn.png'
          /> */}
        </div>

        {/* 우상단 */}
        <div className='flex flex-col justify-start items-center absolute right-[4vw] top-[40vw] h-[40vw]'>
          {/* <QuickButton /> */}
        </div>

        {/* 하단 */}
        <div className='absolute flex justify-between items-center bottom-[16vw] px-[2vw] w-full'>
          <div className='flex flex-row flex-1 gap-x-2 justify-start items-center'>
            <QuickButton
              onClick={() => {
                navigate(
                  `../farmPage?token=${AuthStore?.token}&itemType=${UserStore?.itemType}`,
                )
              }}
            />
          </div>
          {!isGameEnd && UserStore?.teamAlfarmData?.itemType && (
            <div className='flex relative mt-[20vw]'>
              {!waterModalVisible ? (
                <BasicButton
                  type='water'
                  action='giveWater'
                  disabled={
                    UserStore?.farmData?.water < 10 || !canClickGiveWater
                  }
                  label={
                    UserStore?.farmData?.nutriment === undefined
                      ? `0g`
                      : `${commaNumber(
                          Math.floor(UserStore?.farmData?.water),
                        )}g`
                  }
                  onClick={() => {
                    giveWaterBasketToTeamAlfarm()
                  }}
                />
              ) : (
                <BasicButton
                  type='water'
                  action='givingWater'
                  disabled={true}
                  label={
                    UserStore?.farmData?.nutriment === undefined
                      ? `0g`
                      : `${commaNumber(
                          Math.floor(UserStore?.farmData?.water),
                        )}g`
                  }
                />
              )}
              <div className='absolute -top-[25vw]'>
                <BasicButton
                  type='waterFive'
                  action='giveWaterFive'
                  disabled={
                    UserStore?.farmData?.water < 50 || !canClickGiveWater
                  }
                  onClick={() => {
                    giveWaterBasketToTeamAlfarm(5)
                  }}
                />
              </div>
            </div>
          )}

          {isGameEnd &&
            UserStore?.teamAlfarmData?.myData?.leader &&
            UserStore?.teamAlfarmData?.members
              ?.filter((item) => item)
              ?.every((item) => item?.level > 6) && (
              <div
                className='w-[70vw] rounded-xl bg-gradient-to-r from-[#F7B44F] via-[#FFC061] to-[#F7B44F] text-[#442b22] font-bold px-[4vw] py-[4vw] flex justify-center items-center mr-[2vw]'
                onClick={() => {
                  setShowHarvestModal(true)
                }}
              >
                수확하기
              </div>
            )}

          {!UserStore?.teamAlfarmData?.itemType &&
            !UserStore?.teamAlfarmData?.myData?.leader &&
            UserStore?.teamAlfarmData?.members?.filter((item) => item)
              ?.length >= 2 && (
              <div className='w-[70vw] rounded-xl bg-[#999] text-[#442b22] font-bold px-[4vw] py-[4vw] flex justify-center items-center mr-[2vw]'>
                팀장이 작물을 선택중이에요!
              </div>
            )}

          {!UserStore?.teamAlfarmData?.itemType &&
            UserStore?.teamAlfarmData?.myData?.leader &&
            UserStore?.teamAlfarmData?.members?.filter((item) => item)
              ?.length >= 2 && (
              <div
                className='w-[70vw] rounded-xl bg-gradient-to-r from-[#F7B44F] via-[#FFC061] to-[#F7B44F] text-[#442b22] font-bold px-[4vw] py-[4vw] flex justify-center items-center mr-[2vw]'
                onClick={() => {
                  setOpenSelectItemModal(true)
                }}
              >
                작물을 선택해보세요
              </div>
            )}

          {!UserStore?.teamAlfarmData?.itemType &&
            UserStore?.teamAlfarmData?.myData?.leader &&
            UserStore?.teamAlfarmData?.members?.filter((item) => item)?.length <
              2 && (
              <div
                className='w-[70vw] rounded-xl bg-gradient-to-r from-[#F7B44F] via-[#FFC061] to-[#F7B44F] text-[#442b22] font-bold px-[4vw] py-[4vw] flex justify-center items-center mr-[2vw]'
                onClick={() => {
                  invite()
                }}
              >
                {`팀원 초대하기 (${
                  2 -
                  UserStore?.teamAlfarmData?.members?.filter((item) => item)
                    ?.length
                }명 남음)`}
              </div>
            )}
        </div>
      </div>

      {tutorialOn && <TeamAlfarmExplainModal setTutorialOn={setTutorialOn} />}

      {openSelectItemModal && UserStore?.teamAlfarmData?.myData?.leader && (
        <TeamAlfarmSelectItemTypeModal
          token={token}
          farmData={UserStore?.farmData}
          setFarmData={() => {}}
          itemType={'null'}
          setOpenSelectItemModal={setOpenSelectItemModal}
        />
      )}

      {ModalStore?.isModalOpen === 'teamAlfarmCheckIn' && (
        <DoubleExpEventModal />
      )}

      {showQuitModal && (
        <TeamAlfarmQuitModal
          close={() => {
            setShowQuitModal(false)
          }}
          quitTeam={quitTeam}
        />
      )}

      {showlevelUpRewardModal && (
        <TeamAlfarmBasicModal
          close={() => {
            setShowlevelUpRewardModal(false)
          }}
        />
      )}

      {showHarvestModal && (
        <TeamAlfarmBasicModal
          src='url(../teamAlfarm/modal/harvestModal.png)'
          close={() => {
            setShowHarvestModal(false)
          }}
          onClick={() => {
            if (
              codePushVersionAvailable(
                UserStore?.codePushVersionFromAlwayzApp,
                '4.6.59',
              )
            ) {
              const refinedItemType =
                UserStore?.teamAlfarmData?.itemType.charAt(0).toUpperCase() +
                UserStore?.teamAlfarmData?.itemType.slice(1)?.split('_')?.[0]
              harvestData(`#openAlfarm${refinedItemType}Team`)
            } else {
              ToastStore.setToastOn({
                type: 'error',
                message: '수확을 위해 앱을 업데이트 해주세요!',
                duration: 2000,
              })
            }
          }}
        />
      )}

      {showHarvestWaitModal && (
        <TeamAlfarmBasicModal
          src='url(../teamAlfarm/modal/harvestWaitModal.png)'
          close={() => {
            setShowHarvestWaitModal(false)
          }}
        />
      )}

      <Modal
        isOpen={waterModalVisible}
        onRequestClose={() => {}}
        className='modal-basic relative w-full h-[130%]'
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0)',
          },
        }}
      >
        <WaterEffect isTeamAlfarm={true} />
      </Modal>
    </div>
  )
})

export default TeamAlfarm
