import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import userButtonClickLogger from 'utils/userButtonClickLogger'

const sprite = {
  sevendaysEvent: {
    images: [`/icon/leftScrollImage/welcomeEventv2.png`],
  },
  canGetSevendaysEvent: {
    images: [`/icon/leftScrollImage/canGetSevendaysEventv2.png`],
  },
  comebackUser: {
    images: [`/icon/leftScrollImage/comebackEventv3.png`],
  },
  inviteQuest: {
    images: [`/icon/leftScrollImage/inviteFriendsEventv2.png`],
  },
  canGetAttendanceWaterIcon: {
    images: [`/icon/leftScrollImage/canCheckIconv2.png`],
  },
  attendanceWater: {
    images: [`/icon/leftScrollImage/checkIconv2.png`],
  },
  qrIcon: {
    images: [`/icon/leftScrollImage/qrIconv2.png`],
  },
  makeGganbu: {
    images: [`/icon/leftScrollImage/makeGganbuv2.png`],
  },
  makeGganbuAlert: {
    images: [`/icon/leftScrollImage/makeGganbuAlertv2.png`],
  },
  vipCard: {
    images: [`/icon/leftScrollImage/vipEntryIconv2.png`],
  },
  inviteQuestV2: {
    images: [`/icon/leftScrollImage/inviteFriendsEventv2.png`],
  },
  engagementNutriment: {
    images: [`/icon/leftScrollImage/engageNutrimentv2.png`],
  },
  roulette: {
    images: [`/icon/leftScrollImage/roulettev2.png`],
  },
  harvestInvite: {
    images: [`/icon/leftScrollImage/harvestInvitev2.png`],
  },
  friendGarden: {
    images: [`/icon/leftScrollImage/friendGardenv2.png`],
  },
  grapeEventLeft: {
    images: [`/icon/leftScrollImage/grapeEventLeft.png`],
  },
  dailyMission: {
    images: [`/icon/leftScrollImage/dailyMissionIcon.png`],
  },
  pet: {
    images: [`/icon/leftScrollImage/petIcon.png`],
  },
  sansin: {
    images: [`/icon/leftScrollImage/SanSinLeftScrollEntryIcon.png`],
  },
  mysteryFlower: {
    images: [`/icon/leftScrollImage/mysteryFlowerIcon.png`],
  },
}

const LeftScrollButton = observer(
  ({
    imageType = 'sevendaysEvent',
    onClick,
    className = '',
    style,
    onTimeEnd = () => {},
    timeStamp,
    timerMinutes,
    startFromMinutes = false,
    timerStyle,
    interval = 1000,
    noDays = false,
    wobble = false,
    wobblePuppy = false,
    wobbleImage = 'fertBubble',
    pingType = null,
  }) => {
    const throttleRef = useRef({})
    if (!(imageType in sprite)) return null
    const [timerColor, setTimerColor] = useState('#442b22')
    const [hidden, setHidden] = useState(false)

    useEffect(() => {
      let date = new Date()

      if (date.getHours() >= 20 || date.getHours() <= 6) {
        setTimerColor('#F5F5F5')
      } else {
        setTimerColor('#442b22')
      }
    }, [])

    return (
      <button
        id={imageType}
        onClick={() => {
          onClick()
          AlfarmEventLogger({
            throttleRef,
            locationType: 'leftScrollButton',
            locationName: imageType,
            eventType: 'click',
            collection: 'UserAlfarmClickLog',
          })
          userButtonClickLogger({
            buttonType: imageType,
          })
        }}
        className={`bt-header ${className}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '1vw',
          position: 'relative',

          ...style,
        }}
      >
        {pingType && (
          <img
            className='w-[9vw] h-[9vw] absolute -right-[2vw] -top-[2vw]'
            alt='ping'
            src={
              pingType === '!' ? `../icon/!.webp` : `../icon/${pingType}.png`
            }
          />
        )}
        {wobble && (
          <div
            style={{
              position: 'absolute',
              width: '50%',
              right: '-1.5vw',
              top: '-3vw',
            }}
            className='wobble-ver-left'
          >
            <img src={`/dailyInvite/${wobbleImage}.png`} alt='' />
          </div>
        )}
        {wobblePuppy && (
          <div
            style={{
              position: 'absolute',
              width: '50%',
              right: '-1vw',
              top: '-3vw',
            }}
            className='wobble-ver-left'
          >
            <img src='/dailyInvite/fertBubble.png' alt='' />
          </div>
        )}
        <img src={sprite[imageType].images} alt='' />
      </button>
    )
  },
)
export default LeftScrollButton
