import semverCompareBuild from 'semver/functions/compare-build'

export const safeCodepushVersionCompare = (
  versionA: string | undefined,
  versionB: string | undefined,
) => {
  const safeVersion = (ver: string | undefined) => {
    if (!ver || !ver.includes('.')) return '0.0.0'

    return ver
  }

  const safeVersionA = safeVersion(versionA)
  const safeVersionB = safeVersion(versionB)

  const versionComparison = semverCompareBuild(safeVersionA, safeVersionB)
  return versionComparison
}
