import { motion } from 'framer-motion'
import clsx from 'clsx'
import { useRive, useStateMachineInput } from '@rive-app/react-canvas'
import { useEffect, useState } from 'react'
import { StrokeText } from 'utils/strokeText'
import backendApis from 'utils/backendApis'
import AlertModal from 'comps/atoms/AlertModal'

const InfoText = ({ stage }) => (
  <motion.div
    initial={{ scale: 0.5, y: 50, opacity: 0 }}
    animate={{ scale: 1, y: 0, opacity: 1 }}
    transition={{
      type: 'tween',
      stiffness: 120,
      damping: 16,
    }}
    className='w-[290px] h-[164px] rounded-[20px] bg-white '
  >
    {(() => {
      switch (stage) {
        case 1:
          return (
            <div className='flex flex-col items-center justify-center w-full h-full'>
              <div
                className='text-[#5C3112] font-bold text-[24px] leading-6 text-center mb-[10px]'
                style={{ fontFamily: 'Pretendard, sans-serif' }}
              >
                올팜이
                <br />
                새로워졌어요!
              </div>
              <div
                className='text-[#5C3112] text-[18px] leading-6 text-center'
                style={{ fontFamily: 'Pretendard, sans-serif' }}
              >
                더 즐거운 올팜을 만들기 위해
                <br />
                대규모 업데이트를 진행했어요
              </div>
            </div>
          )

        case 2:
          return (
            <div className='flex flex-col items-center justify-center w-full h-full'>
              <div
                className='text-[#5C3112] font-bold text-[24px] leading-6 text-center mb-[10px]'
                style={{ fontFamily: 'Pretendard, sans-serif' }}
              >
                내 농장의 정보는
                <br />
                안전하게 옮겨드릴게요
              </div>
              <div
                className='text-[#5C3112] text-[18px] leading-6 text-center'
                style={{ fontFamily: 'Pretendard, sans-serif' }}
              >
                가지고 계신 물, 비료, 양분은
                <br />
                그대로 가져가요
              </div>
            </div>
          )

        case 3:
          return (
            <div className='flex flex-col items-center justify-center w-full h-full'>
              <div
                className='text-[#5C3112] font-bold text-[24px] leading-6 text-center mb-[10px]'
                style={{ fontFamily: 'Pretendard, sans-serif' }}
              >
                고급비료 5개를
                <br />
                선물로 준비했어요
              </div>
              <div
                className='text-[#5C3112] text-[18px] leading-6 text-center'
                style={{ fontFamily: 'Pretendard, sans-serif' }}
              >
                새로운 올팜에서도
                <br />잘 부탁드려요!
              </div>
            </div>
          )

        default:
          return null
      }
    })()}
  </motion.div>
)

const RoundButton = ({ stage, onClick }) => {
  return (
    <div className='relative' onClick={onClick}>
      <img
        src={
          stage === 3
            ? 'migration/orange_button.png'
            : 'migration/green_button.png'
        }
        className='w-[240px] h-[62px]'
      />
      <div className='absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
        <StrokeText
          strokeWidth={7}
          fontSize={24}
          strokeColor={stage === 3 ? '#D85E00' : '#1A758A'}
          text={stage === 3 ? '올팜 시작하기' : '다음'}
        />
      </div>
    </div>
  )
}

const cloudConfigs = [
  {
    top: '25%',
    left: '-10%',
    width: 'w-28',
  },
  {
    top: '34%',
    left: '60%',
    width: 'w-32',
  },
  {
    top: '8%',
    left: '50%',
    width: 'w-16',
  },
  {
    top: '6%',
    left: '70%',
    width: 'w-48',
  },
]

const MigrationInfoScreen = () => {
  const [stage, setStage] = useState(1)
  const [modalOpen, setModalOpen] = useState(false)

  const { rive, RiveComponent } = useRive({
    src: 'migration/rive/migration.riv',
    artboard: 'MigrationImg',
    stateMachines: 'StateMachine',
    autoplay: true,
    useOffscreenRenderer: true,
  })

  const nextStageInput = useStateMachineInput(rive, 'StateMachine', 'Next')

  useEffect(() => {
    backendApis.recordEventLog({
      collection: 'UserAlfarmPageviewLog',
      page: 'migrationInfo',
      locationName: 'migrationInfo',
      locationType: 'migrationInfo',
      eventType: 'pageview',
      eventName: 'migrationToV2',
      needSampling: false,
    })
  }, [])

  const migrationToV2 = async () => {
    backendApis.recordEventLog({
      collection: 'UserAlfarmClickLog',
      page: 'migrationInfo',
      locationName: 'migrationInfo',
      locationType: 'migrationInfo',
      eventType: 'click',
      eventName: 'migrationToV2',
      needSampling: false,
    })
    const result = await backendApis.migrationToV2()
    if (result?.status === 200) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ type: 'migrationV2' }),
      )
      return
    } else {
      setModalOpen(true)
      return
    }
  }

  return (
    <div
      className='flex flex-col items-center justify-center w-screen h-screen'
      style={{
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
        backgroundImage: "url('public/migration/v2_background.png')",
      }}
    >
      {/* background */}
      <motion.img
        src='migration/sunray.png' // 너가 쓰는 햇살 이미지 경로
        alt='sunray'
        className='absolute top-0 z-0 w-screen h-screen pointer-events-none select-none -left-20'
        animate={{ opacity: [0, 1, 0] }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: 'easeInOut',
        }}
      />
      {cloudConfigs.map((cloud, idx) => (
        <motion.img
          key={idx}
          src='migration/cloud.png'
          alt={`cloud-${idx}`}
          className={clsx(
            'absolute h-auto opacity-20 pointer-events-none',
            cloud.width,
          )}
          style={{ top: cloud.top, left: cloud.left }}
          animate={{ x: [0, -500] }}
          transition={{
            duration: 300,
            repeat: Infinity,
            repeatType: 'loop',
            ease: 'linear',
          }}
        />
      ))}
      <RiveComponent className='w-[390px] h-[390px] mt-[60px]' />
      <InfoText stage={stage} />

      {/* progress */}
      <div className='flex flex-row gap-[6px] mt-[14px] mb-[26px]'>
        {[1, 2, 3].map((elem) => {
          if (elem === stage)
            return <div className='w-[8px] h-[8px] bg-white rounded-full' />
          return (
            <div className='w-[8px] h-[8px] bg-white rounded-full opacity-35' />
          )
        })}
      </div>

      <RoundButton
        stage={stage}
        onClick={() => {
          if (stage === 3) {
            migrationToV2()
          } else {
            if (nextStageInput) {
              nextStageInput?.fire()
              setStage((prev) => prev + 1)
            }
          }
        }}
      />

      {modalOpen && (
        <AlertModal
          enteringRoute='errorAlert'
          setAlertModal={setModalOpen}
          setInviteResultStatus={() => {}}
        />
      )}
    </div>
  )
}

export default MigrationInfoScreen
