import React, { useEffect, useRef, useState } from 'react'

import clsx from 'clsx'

export const StrokeText = (props) => {
  const {
    strokeColor = '#1A758A',
    strokeWidth = 3,
    fontSize = 14,
    textColor = 'white',
    text,
    ...rest
  } = props
  const textRef = useRef(null)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (textRef.current) {
      setWidth(textRef.current.offsetWidth)
    }
  }, [textRef, fontSize])

  return (
    <span
      className={clsx(
        'relative inline-block overflow-visible mt-[1px]',
        rest.className,
      )}
      {...rest}
    >
      <svg
        width={width || 1}
        height={fontSize + 2}
        viewBox={`0 0 ${width || 1} ${fontSize + 2}`}
        className='overflow-visible'
      >
        <text
          x='50%'
          y='50%'
          textAnchor='middle'
          dominantBaseline='middle'
          style={{
            fontSize: `${fontSize}px`,
            stroke: strokeColor,
            strokeWidth,
          }}
          className='font-bold whitespace-pre stroke-linecap-round stroke-linejoin-round font-maple'
        >
          {text}
        </text>
      </svg>
      <div
        ref={textRef}
        className='absolute font-bold whitespace-pre -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 font-maple'
        style={{
          fontSize: `${fontSize}px`,
          color: textColor,
        }}
      >
        {text}
      </div>
    </span>
  )
}
