import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../store/ModalStore'
import { safeCodepushVersionCompare } from 'utils/semver'

const PhotoReviewModal = observer(
  ({ token, farmData, photoReviewItemsInfo, userCodePushVersion }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)

    const photoReviewRewardButtonClicked = async () => {
      const purchaseData = {
        enteringComponent: 'Alfarm_PhotoReviewScreen',
        source:
          'https://assets.ilevit.com/1b6ec647-f56b-4c25-8cda-daec68e79d97.png',
        screen: 'AlfarmPhotoReviewScreen',
        rewardType: 'photoReviewWater',
        photoReviewItemsInfo,
      }
      if (
        safeCodepushVersionCompare(userCodePushVersion, '2.2.53') >= 0
      ) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'photoReviewItemsInfo',
            purchaseData,
          }),
        )
      } else {
        window.location.href = `#photoReviewWater.${JSON.stringify(
          purchaseData,
        )}`
      }
    }

    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <button
            style={{
              width: '10%',
              top: '15%',
              right: '10%',
              position: 'absolute',
              zIndex: 1113,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div className='w-[80vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
            <img
              src='https://assets.ilevit.com/333baae8-c348-4c31-a9a7-b3e6911ef494.png'
              alt='abc'
              style={{ width: '80vw' }}
            />
            <div
              style={{
                background: isButtonPressed ? '#F7C82A' : '#F7C82A',
                boxShadow: isButtonPressed
                  ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                  : '',
                width: '60vw',
                height: '14vw',
                borderRadius: 99,
                color: 'black',
                fontFamily: 'maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1rem',
                position: 'absolute',
                bottom: '8vw',
                left: '10vw',
              }}
              onClick={() => {
                photoReviewRewardButtonClicked()
                ModalStore.setIsModalOpen('basic')
              }}
              onPointerDown={() => {
                setIsButtonPressed(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
              }}
            >
              사진 후기 작성하고 혜택 받기
            </div>
          </div>
          {/* <div
                style={style.insideModalStyle}
               
              >
                <img
                  src="https://assets.ilevit.com/ceb9ca7e-7bb3-4955-be7e-0615ea1f3fb6.png"
                  alt="abc"
                  style={{ width: "80vw" }}
                />
              </div> */}
        </div>
      </>
    )
  },
)

export default PhotoReviewModal
