import { farmingList } from '../../config'

const PlantNameAndAmount = (itemType) => {
  if (itemType === 'mool')
    return {
      koreanName: '생수',
      amount: '2리터 6개',
      postfix: '를',
      ongoingGameCount: '11만명이',
    }
  if (itemType === 'mychew')
    return { koreanName: '마이쮸', amount: '1개', postfix: '를' }
  if (itemType === 'pineapple') {
    return {
      koreanName: '파인애플',
      amount: '1통',
      postfix: '을',
      ongoingGameCount: '4만명이',
    }
  }
  if (itemType === 'asparagus')
    return {
      koreanName: '아스파라거스',
      amount: '200g',
      postfix: '을',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'sprout')
    return {
      koreanName: '방울양배추',
      amount: '200g',
      postfix: '을',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'pepper')
    return {
      koreanName: '오이고추',
      amount: '500g',
      postfix: '을',
      ongoingGameCount: '1만명이',
    }

  if (itemType === 'mandarin')
    return {
      koreanName: '감귤',
      amount: '1.5kg',
      postfix: '을',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'persimmon')
    return {
      koreanName: '감',
      amount: '1.5kg',
      postfix: '을',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'onion')
    return {
      koreanName: '양파',
      amount: '1kg',
      postfix: '을',
      ongoingGameCount: '8만명이',
    }
  if (itemType === 'apple')
    return {
      koreanName: '사과',
      amount: '3과',
      postfix: '를',
      ongoingGameCount: '9만명이',
    }
  if (itemType === 'sweetpotato')
    return {
      koreanName: '고구마',
      amount: '1kg',
      postfix: '를',
      ongoingGameCount: '12만명이',
    }
  if (itemType === 'tomato')
    return {
      koreanName: '방울토마토',
      amount: '500g',
      postfix: '을',
      ongoingGameCount: '20만명이',
    }
  if (itemType === 'potato')
    return {
      koreanName: '감자',
      amount: '1kg',
      postfix: '을',
      ongoingGameCount: '9만명이',
    }
  if (itemType === 'lemon')
    return {
      koreanName: '레몬',
      amount: '4과',
      postfix: '를',
      ongoingGameCount: '2만명이',
    }
  if (itemType === 'carrot')
    return {
      koreanName: '당근',
      amount: '1kg',
      postfix: '을',
      ongoingGameCount: '3만명이',
    }
  if (itemType === 'coffee_starbucks')
    return {
      koreanName: '스타벅스 커피',
      amount: '1잔',
      postfix: '을',
      ongoingGameCount: '11만명이',
    }
  if (itemType === 'pear')
    return {
      koreanName: '배',
      amount: '1.5kg',
      postfix: '을',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'egg')
    return {
      koreanName: '계란',
      amount: '10구',
      postfix: '를',
      ongoingGameCount: '14만명이',
    }
  if (itemType === 'orange')
    return {
      koreanName: '오렌지',
      amount: '3개',
      postfix: '를',
      ongoingGameCount: '3만명이',
    }
  if (itemType === 'greenOnion')
    return {
      koreanName: '대파',
      amount: '400g',
      postfix: '을',
      ongoingGameCount: '3만명이',
    }
  if (itemType === 'banana')
    return {
      koreanName: '바나나',
      amount: '한송이',
      postfix: '를',
      ongoingGameCount: '3만명이',
    }
  if (itemType === 'grape')
    return {
      koreanName: '포도',
      amount: '450g',
      postfix: '을',
      ongoingGameCount: '2만명이',
    }
  if (itemType === 'grapefruit')
    return {
      koreanName: '자몽',
      amount: '2과',
      postfix: '를',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'avocado')
    return {
      koreanName: '아보카도',
      amount: '2과',
      postfix: '를',
      ongoingGameCount: '3만명이',
    }
  if (itemType === 'rice')
    return {
      koreanName: '쌀',
      amount: '900g',
      postfix: '을',
      ongoingGameCount: '3만명이',
    }
  if (itemType === 'lime')
    return {
      koreanName: '라임',
      amount: '3과',
      postfix: '를',
      ongoingGameCount: '3만명이',
    }
  if (itemType === 'galic' || itemType === 'garlic')
    return {
      koreanName: '마늘',
      amount: '300g',
      postfix: '을',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'mychew')
    return {
      koreanName: '마이쮸 포도맛',
      amount: '1개',
      postfix: '를',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'coffee_mega')
    return {
      koreanName: '메가 커피',
      amount: '1개',
      postfix: '를',
      ongoingGameCount: '26만명이',
    }
  if (itemType === 'coffee_compose')
    return {
      koreanName: '컴포즈 커피',
      amount: '1개',
      postfix: '를',
      ongoingGameCount: '14만명이',
    }
  if (itemType === 'ramen')
    return {
      koreanName: '신라면 (컵)',
      amount: '1개',
      postfix: '를',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'pokachip')
    return {
      koreanName: '포카칩',
      amount: '1봉지',
      postfix: '를',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'toothpaste')
    return {
      koreanName: '페리오 펌핑치약 허브',
      amount: '1개',
      postfix: '를',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'dishdetergent') {
    return {
      koreanName: '퐁퐁 주방세제',
      amount: '1개',
      postfix: '를',
      ongoingGameCount: '1만명이',
    }
  }
  if (itemType === 'toothset') {
    return {
      koreanName: '핑크솔트 칫솔세트',
      amount: '1개',
      postfix: '를',
      ongoingGameCount: '6천명이',
    }
  }
  if (itemType === 'milk')
    return {
      koreanName: '서울우유',
      amount: '500ml',
      postfix: '를',
      ongoingGameCount: '1만명이',
    }

  // team info start
  if (itemType === 'egg_team')
    return {
      koreanName: '계란',
      amount: '10구 3팩',
      postfix: '을',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'apple_team')
    return {
      koreanName: '사과',
      amount: '3과 3세트',
      postfix: '를',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'pineapple_team')
    return {
      koreanName: '파인애플',
      amount: '1개 3세트',
      postfix: '를',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'sweetpotato_team')
    return {
      koreanName: '고구마',
      amount: '3kg 한박스',
      postfix: '를',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'potato_team')
    return {
      koreanName: '감자',
      amount: '3kg 한박스',
      postfix: '를',
      ongoingGameCount: '1만명이',
    }
  if (itemType === 'rice_team')
    return {
      koreanName: '쌀',
      amount: '900g 3팩',
      postfix: '을',
      ongoingGameCount: '1만명이',
    }
  return
}

const IdFromPlantName = (itemType) => {
  return farmingList.indexOf(itemType) ? farmingList.indexOf(itemType) : 0
}

const PlantBasicInfo = {
  PlantNameAndAmount,
  IdFromPlantName,
}

export default PlantBasicInfo
